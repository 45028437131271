import { GetterTree } from 'vuex';
import { RootState } from './types';

import { CutoutsGetters } from './modules/cutoutsModule';
import { OverlayGetters } from './modules/overlayModule';
import { WindowGetters } from './modules/windowModule';
import { GroupURIGetters } from './modules/groupUriModule';
import { PageMetaGetters } from './modules/metadataModule';
import { ModulesLoadGetters } from './modules/modulesLoadModule';
import { ToastGetters } from '@/store/modules/toastModule';

export { CUTOUTS } from './modules/cutoutsModule';

export {
  windowSize,
  windowWidth,
  isInBreakpoint,
  windowOffset,
  windowOffsetPercent,
  anchorBarOffset,
} from './modules/windowModule';

export { anchorBarLinks, currentUri, HAS_ANCHOR_BAR } from './modules/groupUriModule';

export interface RootGetters
  extends CutoutsGetters,
    OverlayGetters,
    WindowGetters,
    GroupURIGetters,
    PageMetaGetters,
    ModulesLoadGetters,
    ToastGetters {}

const getters: GetterTree<RootState, RootState> = {};

export default getters;
