const messages = {
  footer: {
    phone: '@:header.phoneToCall | @:header.contactSubPhone'
  },
  header: {
    phoneToCall: '866-537-5099',
    contactPhone: '@:header.phoneToCall',
    contactPhoneTitle: '@:footer.phone',
    contactSubPhone: '646-969-4997',
    shortPhone: '@:header.phoneToCall | @:header.phoneToCall',
    navigationPhoneString: 'Call @:header.shortPhone'
  }
}
export default messages
