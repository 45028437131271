import enUS from './en-US.js'
import enAU from './en-AU.js'
import enHYATT from './en-HYATT.js'
import enWorld from './en-001.js'

const messages = {
  'en-US': enUS,
  'en-AU': enAU,
  'en-HYATT': enHYATT,
  'en-001': enWorld,
};

export default messages
