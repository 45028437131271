interface ConvertUtmParam {
  key: string;
  convert: (value?: any) => string;
}

const convertList: ConvertUtmParam[] = [
  {
    key: 'PagePath',
    convert: () => location.pathname,
  },
];

export function getEventParams(params, parentParams) {
  //   console.log('params', params, parentParams);

  const result = {
    ...parentParams,
    ...params,
  };

  Object.keys(result)
    .map((key) => convertList.find((p) => p.key === key))
    .filter((p) => p)
    .forEach((process) => {
      result[process.key] = process.convert(result[process.key]);
    });

  return result;
}
