import './public-path.js';

import 'es6-promise/auto';
import '@/plugins/index';

import Vue from 'vue';
import App from './App.vue';

import { createStore } from '@/store/index';
import { getI18n } from './plugins/i18n';

import EpiEdit from './directives/epiEdit';
Vue.directive('epi-edit', EpiEdit);

// import { initialState } from './testState';

const initialState = (window as any).__INITIAL_STATE__;

// if (initialState) {
//   initialState?.Modules?.PageData?.Slots.forEach((s) => {
//     if (s.Modules.length === 2 && s.Modules[1].Type === 'FixSSRModule') s.Modules.splice(1, 1);
//   });
// }

//console.log('is', initialState);
// console.log('Main', initialState.Modules.PageData.Slots[1].Modules);

function mountApp(store, hydrate: boolean) {
  if (!hydrate) {
    document.querySelector('#app').removeAttribute('data-server-rendered');
    document.querySelector('#app').innerHTML = '';
  }

  new Vue({
    i18n: getI18n(store.state.Meta),
    store,
    render: (h) => h(App),
  }).$mount('#app', hydrate);
}

createStore(initialState).then((store) => {
  const isHydrate = store.state.Modules.IsHydrate;
  try {
    mountApp(store, isHydrate);
  } catch (ex) {
    //console.log('Error trying to mount VueJS app', ex);
    //console.log('Try to mount without hydration');

    mountApp(store, false);
  }
});
