import { isLocal, devRoot, isPhone, isDesktopSafari } from './commonUtils';
import ImageItem from '@/common/interfaces/ImageItem';
import { ImageSize, BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';

export const getUrlWithDomain = (url: string) => (isLocal() ? `${devRoot}${url}` : url);

export const getImageUrl = function (image: ImageItem, size: ImageSize, retina: boolean = false, fitMode: string = 'crop', quality: string = '50') {
  //For frontend. What is the use of the renita parameter if its value is always false?
  let { width, height } = retina ? ImageSize.getDoubleSize(size) : size;
  const retinaParam = isPhone() || isDesktopSafari() ? ' 2x' : '';

  let addParams: any = {
    mode: fitMode, // TODO for 7002 Backend: check if there is a difference between mode and fit params
  };
  // TODO for 7002 Backend: scale param: if image width >= 1500 and else
  if (width >= BREAKPOINTS.xxl) {
    addParams.scale = 'none';
  } else {
    addParams.scale = 'both';
  }
  if (image.FocalPoint) {
    const crop = getCropDemensions(image, width, height);
    addParams = {
      crop,
      fit: 'crop', // TODO for 7002 Backend: check if there is a difference between mode and fit params
    };
  } else if (fitMode === 'pad') {
    addParams.mode = 'crop'
  }
  addParams.quality = quality;
  const params = { width, height, ...addParams };
  const stringParams =
    Object.keys(params)
      .map((k: string) => `${k}=${params[k]}`)
      .join('&') + retinaParam;

  const url = getUrlWithDomain(image.Url);
  return `${url}?${stringParams}`;
};

export const getCropDemensions = function(originalImage: ImageItem, width: number, height: number) {
  const { OriginalWidth, OriginalHeight, FocalPoint } = originalImage;

  if (!FocalPoint) return '0,0,0,0';

  const sourceWidth = OriginalWidth || 1;
  const sourceHeight = OriginalHeight || 1;

  const focalPointY = Math.round(sourceHeight * (FocalPoint.y / 100));
  const focalPointX = Math.round(sourceWidth * (FocalPoint.x / 100));

  const sourceAspectRatio = sourceWidth / sourceHeight;
  const targetAspectRatio = width / height;

  let x1 = 0;
  let x2 = 0;
  let y1 = 0;
  let y2 = 0;
  if (Math.round(targetAspectRatio * 100) == Math.round(sourceAspectRatio * 100)) {
    x2 = OriginalWidth || 0;
    y2 = OriginalHeight || 0;
  } else if (Math.round(targetAspectRatio * 100) > Math.round(sourceAspectRatio * 100)) {
    // the requested aspect ratio is wider than the source image
    const newHeight = Math.floor(sourceWidth / targetAspectRatio);
    x2 = sourceWidth;
    y1 = Math.max(focalPointY - Math.round(newHeight / 2), 0);
    y2 = Math.min(y1 + newHeight, sourceHeight);
    if (y2 == sourceHeight) {
      y1 = y2 - newHeight;
    }
  } else {
    // the requested aspect ratio is narrower than the source image
    const newWidth = Math.round(sourceHeight * targetAspectRatio);
    x1 = Math.max(focalPointX - Math.round(newWidth / 2), 0);
    x2 = Math.min(x1 + newWidth, sourceWidth);
    y2 = sourceHeight;
    if (x2 == sourceWidth) {
      x1 = x2 - newWidth;
    }
  }

  return `${x1},${y1},${x2},${y2}`;
};
