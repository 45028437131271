import { Component, Vue } from 'vue-property-decorator';
import NotificationToast from '@/common/interfaces/modules/NotificationToast';
import ToastType from '@/common/interfaces/enums/ToastType';

import Attn from '@/assets/icons/attn.svg?inline';
import Tick from '@/assets/icons/tick.svg?inline';
import Warning from '@/assets/icons/warning-filled.svg?inline';
import Close from '@/assets/icons/close.svg?inline';
import Info from '@/assets/icons/info-color.svg?inline';
import New from '@/assets/icons/new.svg?inline';
import Chevron from '@/assets/icons/chevron.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';

import { Visibility } from '../../../common/interfaces/modules/NotificationToast';

const ToastComponentProps = Vue.extend({
  props: {
    toast: Object as () => NotificationToast,
  },
});

@Component({
  components: {
    Attn,
    Tick,
    Warning,
    Close,
    Info,
    New,
    Chevron,
    Cross,
  },
})
export default class ToastComponent extends ToastComponentProps {
  hidden: boolean = false;
  remove: boolean = false;

  hide() {
    if (this.toast.Visibility == Visibility.Show) {
      return;
    }

    this.hidden = true;

    setTimeout(() => {
      this.remove = true;
    }, 400);
  }

  GetToastType() {
    return ToastType[this.toast.Type];
  }

  ToastMarkupData() {
    const toastType = this.GetToastType();
    let iconName: string = '';

    switch (toastType) {
      case 'Change':
        iconName = 'Tick';
        break;
      case 'Error':
        iconName = 'Close';
        break;
      case 'Info':
        iconName = 'Info';
        break;
      case 'New':
        iconName = 'New';
        break;
      case 'Danger':
      case 'Warning':
        iconName = 'Warning';
        break;
      case 'Attention':
      default:
        iconName = 'Attn';
    }

    return {
      className: toastType ? toastType.toLowerCase() : null,
      iconName: iconName,
    };
  }
}
