import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';

import { RootState } from '@/store/types';
import { RootGetters } from '../getters';

export interface OverlayState {
  IsOverlayOpen: boolean;
  IsLoading: boolean;
}

const actions: ActionTree<OverlayState, RootState> = {};

export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

const mutations: MutationTree<OverlayState> = {
  [OPEN_OVERLAY](state) {
    state.IsOverlayOpen = true;
  },
  [CLOSE_OVERLAY](state) {
    state.IsOverlayOpen = false;
  },
  [SHOW_LOADER](state) {
    state.IsLoading = true;
  },
  [HIDE_LOADER](state) {
    state.IsLoading = false;
  },
};

export const IS_OVERLAY_OPEN = 'IS_OVERLAY_OPEN';
export const LOADER_IS_VISIBLE = 'LOADER_IS_VISIBLE';
export interface OverlayGetters {
  [IS_OVERLAY_OPEN]: boolean;
  [LOADER_IS_VISIBLE]: boolean;
}

const getters: GetterTree<OverlayState, RootState> = {
  [IS_OVERLAY_OPEN](state) {
    return state.IsOverlayOpen;
  },
  [LOADER_IS_VISIBLE](state) {
    return state.IsLoading;
  },
};

export default {
  namespaced: false,
  state: { IsOverlayOpen: false, IsLoading: false },
  actions,
  mutations,
  getters,
} as Module<OverlayState, RootState>;

export const runOverlayWatch = (store: Store<RootState>) => {
  store.watch(
    (state: RootState, getters: RootGetters) =>
      getters[IS_OVERLAY_OPEN] || getters[LOADER_IS_VISIBLE],
    (isOpen: boolean) => {
      if (isOpen) {
        document.body.classList.add('no-scroll');
        document.body.classList.add('overlay-open');
      } else {
        document.body.classList.remove('no-scroll');
        document.body.classList.remove('overlay-open');
      }
    },
  );
};
