import { replaceAllByArrays } from '@/utils/stringUtils';
import objectPath from 'object-path';
import { replaceAll } from './stringUtils';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

export const runAfter = (func: () => void, sec: number = 5) => {
  setTimeout(func, 1000 * sec);
};

export function sort<T>(
  array: T[],
  extractor: (obj: T) => any,
  direction: 'asc' | 'desc' = 'asc',
  mutate: boolean = false,
) {
  return (mutate ? array : [...array]).sort((a, b) => {
    const aValue = extractor(a);
    const bValue = extractor(b);

    const result = aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
    return direction === 'desc' ? -result : result;
  });
}
export function min<T>(array: T[], extractor: (obj: T) => any) {
  return sort<T>(array, extractor, 'asc')[0];
}

export function max<T>(array: T[], extractor: (obj: T) => any) {
  return sort<T>(array, extractor, 'desc')[0];
}

export function sum(array: number[]) {
  return array.reduce((a, b) => a + b, 0);
}

export const isSSR = () => process.env.VUE_ENV === 'server'; //!!process.env.SSR;
export const isLocal = () => process.env.APP_ENV === 'local';

export const devRoot = 'https://stg2019.expeditions.com';

export function getResizeImage(name: string) {
  // Stub to fit MOF image resizing logic
  return name;
}

export function groupBy<T>(array: T[], key: string): { [key: string]: T[] } {
  return array.reduce((acc: { [key: string]: T[] }, c: any) => {
    const currentValue = c[key] as string;

    acc[currentValue] = [...(acc[currentValue] || []), c];

    return acc;
  }, {} as { [key: string]: T[] });
}

export function throttled(delay: number, fn: () => void) {
  let lastCall = 0;
  return function() {
    let dateTime = new Date();
    const now = dateTime.getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    fn();
  };
}

export function removeDublicates<T>(array: T[]) {
  return array.reduce(
    (acc, current) => (acc.includes(current) ? acc : [...acc, current]),
    [] as T[],
  );
}

export const createObjectWithPath = (path: string, value: any) => {
  if (!path) {
    return value;
  }
  path = replaceAll(path, '[]', '.0');

  const result = {};
  objectPath.set(result, path, value);
  return result;
};

export const getPathByParts = (part1: string | undefined, part2: string | undefined): string => {
  const dot = part1 && part2 ? '.' : '';
  part1 = part1 || '';
  part2 = part2 || '';

  const result = `${part1}${dot}${part2}`;

  return result;
};

export const convertPathToArrayCompatible = (path: string) =>
  replaceAllByArrays(path, ['[', ']'], ['.', '']);

export const getFieldByPath = (object: any, path: string): any =>
  objectPath.get(object, convertPathToArrayCompatible(path));

export const setFieldValueByPath = (object: any, path: string, value: any) =>
  objectPath.set(object, convertPathToArrayCompatible(path), value);

export const addWaiter = (checkFunc: Function, handler: Function, time: number = 200) => {
  const interval = setInterval(() => {
    if (checkFunc()) {
      clearInterval(interval);
      handler();
    }
  }, time);
};

export const emptifyFieldsByValueMutation = (object: any) => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    object[key] = '';
  });
};

export const emptifyFieldsByValue = (object: object) => {
  const result: any = { ...object };
  const keys = Object.keys(result);
  keys.forEach((key) => {
    result[key] = undefined;
  });

  return result;
};

export function getNumberFromPrice(price: string) {
  return parseFloat(price.replace(/\D/g, ''));
}

interface TestData<T> {
  name: string;
  data: {
    p: T;
  };
}

export function wrapTestData<T>(data: { [key: string]: T }, onlyCase?: string): TestData<T>[] {
  const result: TestData<T>[] = Object.keys(data).map((key) => ({
    name: key,
    data: { p: data[key] },
  }));

  if (onlyCase) {
    return result.filter((r) => r.name === onlyCase);
  }

  return result;
}

export function getFromTestData<T>(data: TestData<T>[] | undefined, caseName: string) {
  if (!data) return null;

  let result = data.find((d) => d.name === caseName);
  if (result) return result.data.p;
  return null;
}

function preventDefault(event: any) {
  event.preventDefault();
}

export function modalCloseSroll() {
  //Import this function to allow the body to scroll again on mobile when using toggleModalClassList();
  if (window.outerWidth <= 480) {
    document.removeEventListener('touchmove', preventDefault);
  }
}

export function mobileViewPort() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export function toggleModalClasslist() {
  //classes are in FilteringComponent.scss

  //Safari iOS fix for body lock scrolling
  if (window.outerWidth <= 480) {
    document.addEventListener('touchmove', preventDefault, { passive: false });
  }

  document.body.classList.toggle('overlay-noscroll');

  let anchorBar = document.querySelector('.anchor-bar');
  if (anchorBar) {
    anchorBar.classList.toggle('anchor-bar-modal-open');
  }

  let backToTopAnchor = document.querySelector('.back-to-top__anchor');
  if (backToTopAnchor) {
    backToTopAnchor.classList.toggle('show');
  }

  let navAnchorBar = document.querySelector('nav.anchor-bar');
  if (navAnchorBar) {
    navAnchorBar.classList.toggle('anchor-bar--sticky');
  }

  let navigationSticky = document.querySelector('.navigation--sticky');
  if (navigationSticky) {
    navigationSticky.classList.toggle('navigation');
  }
}

// Adding this function that is a copy of toggleModalClasslist() except that it does not add
// the event listener "touchmove". The copy is done to prevent any breaking changes since we're
// close to initial release. I want to remove the event list but the comment made by Serdyuk makes
// me believe that the this resolves some edge case.
export function toggleModalClassListWithoutTouchMove() {
  document.body.classList.toggle('overlay-noscroll');

  let anchorBar = document.querySelector('.anchor-bar');
  if (anchorBar) {
    anchorBar.classList.toggle('anchor-bar-modal-open');
  }

  let backToTopAnchor = document.querySelector('.back-to-top__anchor');
  if (backToTopAnchor) {
    backToTopAnchor.classList.toggle('show');
  }

  let navAnchorBar = document.querySelector('nav.anchor-bar');
  if (navAnchorBar) {
    navAnchorBar.classList.toggle('anchor-bar--sticky');
  }

  let navigationSticky = document.querySelector('.navigation--sticky');
  if (navigationSticky) {
    navigationSticky.classList.toggle('navigation');
  }
}

export const walkObjectDeep = (
  object: object,
  customizer: (rootObject: object, key: string, value: any) => void,
) => {
  const walk = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        customizer(obj, prop, obj[prop]);

        if (isObject(obj[prop])) {
          walk(obj[prop]);
        }
        if (isArray(obj[prop])) {
          obj[prop].forEach(() => {
            walk(obj[prop]);
          });
        }
      }
    }
  };
  walk(object);
};

export const removeNulls = (object: object) => {
  const clone = { ...object };
  const getProp = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (isNull(obj[prop]) || isUndefined(obj[prop])) {
          delete obj[prop];
        }
        if (isObject(obj[prop])) {
          getProp(obj[prop]);
        }
        if (isArray(obj[prop])) {
          obj[prop].forEach((item) => {
            getProp(item);
          });
        }
      }
    }
  };
  getProp(clone);

  return clone;
};

export const getGlobalThis = () => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  // if (typeof global !== 'undefined') return global;
  throw new Error('Unable to locate global `this`');
};

export const isString = (value: any) => typeof value === 'string';
export const isFunc = (value: any) => typeof value === 'function';

export const isDesktopSafari = () => {
  if (!isSSR()) {
    return /Safari/i.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) && !/Mobi|Android/i.test(navigator.userAgent)
  }
};

export const isPhone = () => {
  if (!isSSR()) return /iPad|iPhone|iPod/.test(navigator.userAgent)
};

export const getFormPayload = (label: string, id: number | string) => {
  return {
    form_id: String(id),
    label,
    form_name: label,
    form_type: label,
  }
};

export const scriptToHead = (code: string) => {
  if (!isSSR()) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = code;
    document.head.appendChild(script);
  }
};

export const cloneSimpleObject = (obj: any) => JSON.parse(JSON.stringify(obj));