import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';
import VueScrollTo from 'vue-scrollto';
import cloneDeep from 'lodash/cloneDeep';

import { RootState } from '@/store/types';

import { RootGetters } from '@/store/getters';
import LinkItem from '@/common/interfaces/LinkItem';

import { PageModule } from './modulesLoadModule';

import { addWaiter, isPhone } from './../../utils/commonUtils';
import { anchorBarOffset, isInBreakpoint, ON_SCROLL_WINDOW } from './windowModule';
import { modulePageOffset } from '@/utils/domUtils';

export interface PageUri {
  IsInited: boolean;
  BaseUrl: string;
  QueryParams: string;
  GroupedAreaURI: string;
  GroupedAreaParams: string;
  AnchorHash: string; // Anchorable content module case

  InScroll?: boolean;
  HasAnchorBar?: boolean;
  HasAnchorableContent?: boolean;
}

export interface UpdateUriParams {
  Uri: string;
  Params?: string;
  RemoveTrailingSlash?: boolean;
  Interaction?: boolean;
}

interface ScrollToOptions {
  module: PageModule;
  target: HTMLElement;
  sendFromIO?: boolean;
  callback?: Function;
  options?: ScrollOptions;
}

export const INIT_GROUP_URI = 'INIT_GROUP_URI';
export const SET_FROM_ANCHOR = 'SET_FROM_ANCHOR';
export const CHANGE_FROM_FIRST_VISIBLE = 'CHANGE_FROM_FIRST_VISIBLE';
export const CHANGE_FROM_HISTORY = 'CHANGE_FROM_HISTORY';
export const UPDATE_URL = 'UPDATE_URL';
export const SET_FROM_ANCHORABLE_CONTENT = 'SET_FROM_ANCHORABLE_CONTENT';
export const SCROLL_TO = 'SCROLL_TO';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const ON_SCROLL = 'ON_SCROLL';
export const SET_ANCHOR_HASH_VISIBLE = 'SET_ANCHOR_HASH_VISIBLE';

const getModuleSelector = (module: PageModule) => {
  return `[uuid="${module.UUID}"]`;
};

const actions: ActionTree<PageUri, RootState> = {
  [INIT_GROUP_URI]({ state, rootState, rootGetters, dispatch, commit }) {
    const rootGettersT = rootGetters as RootGetters;
    //console.log(INIT_GROUP_URI, state);

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    window.onpopstate = function (event: PopStateEvent) {
      dispatch(CHANGE_FROM_HISTORY, event);
    };

    if (!state.IsInited) {
      // const anchorHash = window.location.hash;
      // if (anchorHash) {
      //   addWaiter(
      //     () => !document.querySelector('#ssr-stub'),
      //     () =>
      //       dispatch(SET_FROM_ANCHORABLE_CONTENT, decodeURIComponent(anchorHash.replace('#', ''))),
      //     50,
      //   );
      // }
      
      let selector = state.GroupedAreaURI;
      const anchorHash = window.location.hash.split('&')[0];
      if (anchorHash)
        selector = anchorHash;

      const currentModule = rootGettersT.GET_GROUP_URI_MODULE(selector);
      if (currentModule && currentModule.GroupUri) {
        const selector = getModuleSelector(currentModule);

        let counter = 0;
        let maxCounter = 5;
        let currentModuleOffset = null;
        const scrollToGroupUri = () => {
          dispatch(ON_SCROLL_WINDOW);
          dispatch(SCROLL_TO, {
            target: document.querySelector(selector),
            module: currentModule,
            sendFromIO: true,
            callback: () => {
              const first = rootGettersT.GET_FIRST_VISIBLE_MODULE;
              // console.log(
              //   'scrollToGroupUri first visible',
              //   first,
              //   first === currentModule,
              //   currentModule,
              // );
              if (first === currentModule) {
                if (currentModuleOffset === null) {
                  currentModuleOffset = modulePageOffset(currentModule.UUID);
                }
                commit(M_SET_INIT);

                const checkDivShift = () => {
                  const newOffset = modulePageOffset(currentModule.UUID);
                  // console.log(currentModuleOffset, newOffset);
                  if (counter++ < maxCounter) {
                    if (currentModuleOffset?.top !== newOffset?.top) {
                      //console.log('offset changed', currentModuleOffset.top, newOffset.top);

                      let mobileAnchorBarCase = false;
                      const isMobile = rootGettersT[isInBreakpoint](['xs', 'sm', 'md']);
                      if (isMobile) {
                        const shift = Math.abs(currentModuleOffset.top - newOffset.top);
                        const anchorBarSize = Math.abs(rootGettersT[anchorBarOffset]);
                        mobileAnchorBarCase = Math.abs(shift - anchorBarSize) < 3 && isMobile;
                      }

                      if (!mobileAnchorBarCase) {
                        currentModuleOffset = newOffset;
                        scrollToGroupUri();
                      }
                    } else {
                      setTimeout(checkDivShift, 100);
                    }
                  }
                };

                setTimeout(checkDivShift, 100);
              } else {
                setTimeout(scrollToGroupUri, 100);
              }
            },
          } as ScrollToOptions);
        };
        addWaiter(
          () => document.querySelector(selector) && currentModule.IsMounted,
          () => scrollToGroupUri(),
          isPhone() ? 2000 : 50,
        );
      } else {
        commit(M_SET_INIT);
      }
    }
  },
  [SET_FROM_ANCHOR]({ state, commit, dispatch, rootGetters }, uri_h: string) {
    //console.log(SET_FROM_ANCHOR, uri);
    let uri = uri_h.replace('#', '');
    const rootGettersT = rootGetters as RootGetters;
    if (state.GroupedAreaURI !== uri) {
      dispatch(UPDATE_URL, { Uri: uri_h, RemoveTrailingSlash: true, Interaction: true } as UpdateUriParams);
    }
    
    const module = rootGettersT.GET_GROUP_URI_MODULE(state.GroupedAreaURI);
    //console.log(SET_FROM_ANCHOR, module);
    if (module) {
      const selector = getModuleSelector(module);
      dispatch(SCROLL_TO, {
        target: document.querySelector(selector),
        module: module,
        sendFromIO: true,
      } as ScrollToOptions);
    }
  },
  [SET_FROM_ANCHORABLE_CONTENT]({ state, commit, dispatch, rootGetters }, uri: string) {
    if (window.location.hash !== uri) {
      commit(M_SET_ANCHOR_HASH, uri);
      window.location.hash = uri;
    }

    dispatch(SCROLL_TO, {
      target: document.querySelector(`[hash-id="${uri}"]`),
      module: null,
      sendFromIO: false,
    } as ScrollToOptions);
  },
  [SCROLL_TO_TOP]({ dispatch }) {
    const target = document.body.parentElement;
    const duration = document.documentElement.scrollHeight / 6;

    if (target) {
      dispatch(SCROLL_TO, {
        target,
        sendFromIO: true,
        options: {
          duration: duration,
        },
      });
    }
  },
  [CHANGE_FROM_FIRST_VISIBLE]({ state, commit, dispatch, rootGetters }, firstVisible: PageModule) {
    // console.log(CHANGE_FROM_FIRST_VISIBLE, firstVisible?.Id, state.IsInited);
    if (state.IsInited) {
      if (!state.InScroll) {
        if (!firstVisible) {
          const rootGettersT = rootGetters as RootGetters;
          firstVisible = rootGettersT.GET_FIRST_VISIBLE_MODULE;
        }

        let newGroupUri = firstVisible?.GroupUri || '';
        // console.log('firstVisible', firstVisible?.Id, firstVisible?.Type, newGroupUri);
        if (newGroupUri) newGroupUri = '#' + newGroupUri;
        if (newGroupUri != state.GroupedAreaURI) {
          const hashParam = window.location.hash?.split('&')[1];
          if (!hashParam) {
            dispatch(UPDATE_URL, { Uri: newGroupUri, RemoveTrailingSlash: true } as UpdateUriParams);
          }
        }
      }
    }
    // else {
    //   if (state.GroupedAreaURI) {
    //     dispatch(SET_FROM_ANCHOR, state.GroupedAreaURI);
    //   }
    //   commit(M_SET_INIT);
    // }
  },
  [CHANGE_FROM_HISTORY]({ state, dispatch, rootGetters }, event: PopStateEvent) {
    //console.log('history', event.state);

    if (event.state) {
      const module = (rootGetters as RootGetters).GET_MODULES.find(
        (m) => m.GroupUri === history.state.uri,
      );
      if (module) {
        dispatch(SCROLL_TO, {
          target: document.querySelector(getModuleSelector(module)),
          module: module,
          sendFromIO: true,
        } as ScrollToOptions);
      }
    }
  },
  [UPDATE_URL]({ commit, state, dispatch }, uri: UpdateUriParams) {
    //console.log(UPDATE_URL, uri);
    const { GroupedAreaURI, GroupedAreaParams } = state;
    if (GroupedAreaURI !== uri.Uri || uri.Params != GroupedAreaParams) {
      commit(M_UPDATE_URI, uri);
      if (uri.Interaction) {
      // state.Modules.filter((m) => m.parent).forEach((m) => (m.parent.style.border = 'none'));
      // state.Modules.filter((m) => m.parent)
      //   .filter((m) => m.uri === uri)
      //   .forEach((m) => (m.parent.style.border = '5px solid red'));
        if (state.HasAnchorBar) {
          if (!history.state || history.state.uri !== uri.Uri || history.state.params !== uri.Params) { // TODO check
            const params = uri.Params ? `&${uri.Params}` : '';
            const BaseUrl = uri.RemoveTrailingSlash ? state.BaseUrl.replace(/\/$/, '') : state.BaseUrl;
            const newUrl = `${BaseUrl}${state.QueryParams || ''}${state.GroupedAreaURI}${params}`;
            //console.log('update URL', state, newUrl);
            history.pushState(
              {
                uri: uri.Uri,
                params: uri.Params,
              },
              document.title,
              newUrl || '/',
            );
          }
        }
      }
    }
  },
  [SCROLL_TO]({ commit, dispatch, rootGetters }, scrollToOptions: ScrollToOptions) {
    //console.log(SCROLL_TO, scrollToOptions);
    const rootGettersT = rootGetters as RootGetters;
    const { module, target, sendFromIO, callback, options } = scrollToOptions;
    if (target) {
      let offset = rootGettersT.HAS_ANCHOR_BAR ? rootGettersT[anchorBarOffset] : 0;
      if (document.querySelector('.anchor-bar')?.clientHeight > -rootGettersT[anchorBarOffset]) {
        offset = 0;
      }
      offset = offset + 3;

      //console.log('scroll to offset', offset);
      VueScrollTo.scrollTo(target, {
        duration: 0,
        onStart: () => commit(M_SET_IN_SCROLL, true),
        onDone: () => {
          setTimeout(() => {
            commit(M_SET_IN_SCROLL, false);

            if (callback) callback();

            if (sendFromIO) {
              dispatch(CHANGE_FROM_FIRST_VISIBLE, module);
            }
          }, 150);
        },
        offset: offset,
        ...options,
      });
    }
  },
  [SET_ANCHOR_HASH_VISIBLE]({ commit, state }, anchorHash: string) {
    if (!state.InScroll) {
      if (window.location.hash !== anchorHash) {
        commit(M_SET_ANCHOR_HASH, anchorHash);
      }
    }
  },
};

export const M_SET_INIT = 'M_SET_INIT';
export const M_UPDATE_URI = 'UPDATE_URI';
export const M_SET_ANCHORABLE_CONTENT = 'SET_ANCHORABLE_CONTENT';
export const M_SET_IN_SCROLL = 'SET_IN_SCROLL';
export const M_SET_HAS_ANCHOR_BAR = 'M_SET_HAS_ANCHOR_BAR';
export const M_SET_ANCHOR_HASH = 'M_SET_ANCHOR_HASH';
const mutations: MutationTree<PageUri> = {
  [M_SET_INIT](state) {
    //console.log(M_SET_INIT);
    state.IsInited = true;
  },
  [M_UPDATE_URI](state, newUri: UpdateUriParams) {
    //console.log(M_UPDATE_URI, newUri);
    state.GroupedAreaURI = newUri.Uri;
    state.GroupedAreaParams = newUri.Params || '';
  },
  [M_SET_IN_SCROLL](state, inScroll: boolean) {
    state.InScroll = inScroll;
  },
  [M_SET_ANCHORABLE_CONTENT](state, hasAchorableContent: boolean) {
    state.HasAnchorableContent = true;
  },
  [M_SET_HAS_ANCHOR_BAR](state) {
    state.HasAnchorBar = true;
  },
  [M_SET_ANCHOR_HASH](state, hash: string) {
    if (state.AnchorHash !== hash) {
      state.AnchorHash = hash;
    }
  },
};

export const currentUri = 'currentUri';
export const currentUriParams = 'currentUriParams';
export const anchorBarLinks = 'anchorBarLinks';
export const HAS_ANCHOR_BAR = 'HAS_ANCHOR_BAR';
export const CURRENT_ANCHOR_HASH = 'CURRENT_ANCHOR_HASH';
export interface GroupURIGetters {
  currentUri: string;
  currentUriParams: (uri: string) => string;
  anchorBarLinks: LinkItem[];
  currentAnchorHash: string;
  HAS_ANCHOR_BAR: boolean;
}

const getters: GetterTree<PageUri, RootState> = {
  [currentUri]: (state) => state.GroupedAreaURI,
  [currentUriParams]: (state) => (uri: string) => {
    const hashParam = window.location.hash?.split('&')[1];
    if (uri && state.GroupedAreaURI === uri) {
      if (state.GroupedAreaParams.startsWith('/')) {
        return state.GroupedAreaParams.substring(1);
      }
      return state.GroupedAreaParams;
    }
    else if (hashParam) {
      return hashParam;
    }
    return null;
  },
  [anchorBarLinks]: (state, getters, rootState, rootGetters: RootGetters) => {
    const links = rootGetters.GET_MODULES.filter((m) => m.GroupUri)
      .map((m) => ({ Title: m.Id, Link: '#' + m.GroupUri, isHidden: m.IsHiddenGroupedUriInNav}))
      .reduce((acc, current) => (acc.some((l) => l.Link === current.Link) ? acc : [...acc, current]), [])
      .filter(m => !m.isHidden)
    return links;
  },
  [HAS_ANCHOR_BAR]: (state) => state.HasAnchorBar,
  [CURRENT_ANCHOR_HASH]: (state) => state.AnchorHash,
};

export default (initialState: PageUri) =>
  ({
    namespaced: false,
    state: {
      AnchorHash: '',
      BaseUrl: '', // /group-uri/bla/params
      QueryParams: '', // ?NoRedirect=true
      GroupedAreaURI: '', // bla
      GroupedAreaParams: '', // params
      HasAnchorBar: false,
      ...initialState,
    },
    actions,
    mutations,
    getters,
  } as Module<PageUri, RootState>);

export const processInitialState = (initialState: RootState): RootState => {
  const processed = cloneDeep(initialState);
  let url = location.pathname;
  if (!url.endsWith('/')) {
    url += '/';
  }

  const uri = (processed as any).GroupedAreaURI;
  delete (processed as any).GroupedAreaURI;

  processed.PageUri = {
    IsInited: false,
    BaseUrl: '',
    QueryParams: '',
    GroupedAreaURI: uri,
    GroupedAreaParams: '',
    AnchorHash: '',
  };

  if (uri) {
    if (uri.includes('/')) {
      const parts = uri.split('/');
      processed.PageUri.GroupedAreaURI = parts[0];
      processed.PageUri.GroupedAreaParams = parts.slice(1).join('/');
    }

    url = url.replace(`/${uri}`, '/');
    if (url.endsWith('//')) {
      url = url.replace('//', '/');
    }
  }

  processed.PageUri.BaseUrl = url;

  //console.log('state', JSON.stringify(processed));

  return processed;
};

export const runGroupUriWatch = (store: Store<RootState>) => {
  store.watch(
    (state: RootState, getters: RootGetters) => getters.GET_FIRST_VISIBLE_MODULE,
    (firstVisibleModule: PageModule) => {
      // console.log('first visible changed');
      store.dispatch(CHANGE_FROM_FIRST_VISIBLE, firstVisibleModule);
    },
  );

  store.watch(
    (state: RootState, getters: RootGetters) =>
      state.PageUri.IsInited ? null : getters.GET_GROUP_URI_MOUNTED(state.PageUri.GroupedAreaURI),
    (initialGroupUriModule: PageModule) => {
      if (initialGroupUriModule) store.dispatch(INIT_GROUP_URI, initialGroupUriModule);
    },
  );
};
