import { Component, Vue } from 'vue-property-decorator';
import NotificationToast from '@/common/interfaces/modules/NotificationToast';
import Toast from './Toast/Toast.vue';

const NotificationToastComponentProps = Vue.extend({
  props: { loaf: Array as () => NotificationToast[] },
});

@Component({
  components: {
    Toast,
  },
})
export default class NotificationToastComponent extends NotificationToastComponentProps {}
