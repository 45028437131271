import { PluginObject } from 'vue';
import { SegmentElement } from './utm/SegmentElement';

const segmentPlugin: PluginObject<any> = {
  install(Vue) {
    Vue.directive('segment', {
      bind: SegmentElement.RegisterElement,
      unbind: SegmentElement.UnregiserElement,
    });
  },
};

export default segmentPlugin;
