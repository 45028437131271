type SizeBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export default SizeBreakpoints;

export const BREAKPOINTS: { [key in SizeBreakpoints]: number } = {
  xs: 0,
  sm: 560,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

export class ImageSize {
  public width: number;
  public height: number;
  public minWidth: number;

  constructor(sizes: number[]) {
    const [w, h] = sizes;
    this.width = w;
    this.height = h;
  }

  static getDoubleSize(size: ImageSize) {
    return new ImageSize([size.width * 2, size.height * 2]);
  }
}

export type ImageSizesObject = { [key in SizeBreakpoints]?: ImageSize };

export interface ImageSizes {
  def: ImageSize;
  sizes: ImageSizesObject;
}

export interface ImageWidths {
  def: number;
  sizes: { [key in SizeBreakpoints]?: number };
}

export const getClosestBreakpoint = (width: number) => {
  const current = Object.keys(BREAKPOINTS).reduce(
    (acc, current) => {
      const currentWidth = BREAKPOINTS[current as SizeBreakpoints];
      return width >= acc.width && width < currentWidth
        ? acc
        : {
            size: current as SizeBreakpoints,
            width: currentWidth,
          };
    },
    {
      size: 'xs' as SizeBreakpoints,
      width: BREAKPOINTS.xs,
    },
  );

  return current.size;
};

export const CarouselBreakpoints: ImageSizes = {
  def: new ImageSize([560, 812]),
  sizes: {
    sm: new ImageSize([768, 1024]),
    md: new ImageSize([992, 744]),
    lg: new ImageSize([1200, 900]),
    xl: new ImageSize([1440, 1080]),
  },
};

export const ImageSizesQuoteThumbnail: ImageSizes = {
  def: new ImageSize([144, 144]),
  sizes: {
    md: new ImageSize([164, 164]),
  },
};
