import { PropValidator } from 'vue/types/options';
import { SwiperOptions } from 'swiper';
import { Store, Module } from 'vuex';
import { RootState } from '@/store/types';

export function getComponentProps<T>(def: T): { p: PropValidator<T> } {
  return {
    p: {
      type: Object as () => T,
      default: () => def,
    } as PropValidator<T>,
  };
}

export function getPropData(data: any) {
  return { p: data };
}

export function updateByWatchThis(that: any, data: any) {
  Object.keys(data).forEach((key) => {
    if (!Array.isArray(data[key] && !(typeof data[key] === 'object' && data[key] !== null))) {
      if (that[key] !== data[key]) {
        that[key] = data[key];
      }
    }
  });
}

export const swiperPagingRender = (swiper: any, current: number, total: number) =>
  `
<div class="carousel-pagination__frame">
<div class="carousel-pagination__current">${current.toString().padStart(2, '0')}</div>
<div class="carousel-pagination__divider">/</div>
<div class="carousel-pagination__total">${total.toString().padStart(2, '0')}</div>
</div>
  `.trim();

export const getDefaultSwiperOptions = (id: string): SwiperOptions => {
  return {
    slidesPerView: 1.6,
    spaceBetween: -50,
    centeredSlides: true,
    speed: 250,
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: `#s-button-next-${id}`,
      prevEl: `#s-button-prev-${id}`,
    },
    pagination: {
      el: `#s-pagination-${id}`,
      clickable: true,
      type: 'custom',
      renderCustom: swiperPagingRender,
    },
    breakpoints: {
      991: {
        slidesPerView: 1.4,
        spaceBetween: 64,
      },
      767: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
    },
  };
};

export const getModalId = (id: string) => `modal-${id}`;

export const openModal = (modal: any, id: string, isOverflow: boolean = true) => {
  modal.show(id);
  if (isOverflow) {
    document.body.style.overflow = 'hidden';
    const main = document.querySelector('#main-content');
    if (main) {
      main.classList.add('modal-open');
    }
  }
};

export const closeModal = (modal: any, id: string, isOverflow: boolean = true) => {
  modal.hide(id);
  if (isOverflow) {
    document.body.style.overflow = 'auto';
    const main = document.querySelector('#main-content');
    if (main) {
      main.classList.remove('modal-open');
    }
  }
};

export function registerSubModule<R, D>(
  createModule: (data: D) => Module<R, RootState>,
  path: string,
  singleton: boolean = false,
) {
  let count = 0;
  return (store: Store<RootState>, data: D, rewritePath?: string) => {
    const currentPath = rewritePath || (count++ ? `${path}${count}` : path);
    if (singleton && count > 1) {
      return path;
    }

    const module = createModule(data);
    if (rewritePath && !rewritePath.includes('DerSignUpModule')) {
      delete module.state;
    }

    store.registerModule(currentPath, module);
    return currentPath;
  };
}
