export default function install(Vue, options = {}) {
  Vue.mixin({
    computed: {
      uuid() {
        const that = this as any;
        let result;
        if (that.$attrs.uuid) result = that.$attrs.uuid;

        if (!result) {
          let parent = that.$parent;
          while (parent && !parent.$attrs.uuid) {
            options = parent.$options;
            parent = parent.$parent;
          }

          result = parent?.$attrs.uuid || that._uid;
        }
        // console.log('UUID:', result);
        return result;
      },
    },
  });
}
