import LinkItem from '@/common/interfaces/LinkItem';
import ToastType from '@/common/interfaces/enums/ToastType';

export enum Visibility {
  HideOnClick,
  Show,
}

export default interface NotificationToast {
  Link?: LinkItem;
  Type: ToastType;
  Title?: String;
  Text?: String;
  Visibility?: Visibility;
}
