import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

import { RootState } from '@/store/types';
import { getStore } from '@/store/index';
import NotificationToast from '@/common/interfaces/modules/NotificationToast';

export interface ToastState {
  Messages: NotificationToast[];
}

export const SHOW_TOAST = 'SHOW_TOAST';

const actions: ActionTree<ToastState, RootState> = {
  [SHOW_TOAST]({ commit }, messagesArray: [ToastState, number]) {
    const [messages, delay] = messagesArray;
    getStore().commit('MUTATE_MESSAGES', messages);

    if (delay !== 0) {
      setTimeout(() => {
        getStore().commit('MUTATE_MESSAGES', []);
      }, delay);
    }
  },
};

const MUTATE_MESSAGES = 'MUTATE_MESSAGES';

const mutations: MutationTree<ToastState> = {
  [MUTATE_MESSAGES](state, payload) {
    state.Messages = payload;
  },
};

export const TOAST_MESSAGES = 'TOAST_MESSAGES';

export interface ToastGetters {
  TOAST_MESSAGES: NotificationToast[];
}

const getters: GetterTree<ToastState, RootState> = {
  [TOAST_MESSAGES](state) {
    return state.Messages;
  },
};

export default {
  namespaced: false,
  state: { Messages: [] },
  actions,
  mutations,
  getters,
} as Module<ToastState, RootState>;
