import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';

import { RootState } from '@/store/types';
import ImageItem from '@/common/interfaces/ImageItem';

type Cutouts = ImageItem[];

const actions: ActionTree<Cutouts, RootState> = {};

const mutations: MutationTree<Cutouts> = {};

export const CUTOUTS = 'CUTOUTS';
export interface CutoutsGetters {
  [CUTOUTS]: (index: number) => ImageItem | null;
}

const getters: GetterTree<Cutouts, RootState> = {
  [CUTOUTS](state) {
    return (index: number) => (state ? state[index - 1] : null);
  },
};

export default (cutouts: ImageItem[]) =>
  ({
    namespaced: false,
    state: cutouts,
    actions,
    mutations,
    getters,
  } as Module<Cutouts, RootState>);
