import { getStore } from '@/store/index';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';

import TemplateLoading from '@/components/LoadingSpinner/TemplateLevel/TemplateLoading.vue';
import NotificationToastComponent from '@/components/NotificationToast/NotificationToast.vue';
import { LOADER_IS_VISIBLE } from '@/store/modules/overlayModule';
import { TOAST_MESSAGES } from '@/store/modules/toastModule';
import { isSSR } from '@/utils/commonUtils';

@Component({
  components: {
    TemplateLoading,
    NotificationToastComponent,
  },
})
export default class LayoutMixin extends Vue {
  @Getter(LOADER_IS_VISIBLE) isVisible;
  @Getter(TOAST_MESSAGES) toastMessages;

  get isSSR() {
    return isSSR();
  }

  get isEditMode() {
    return getStore().state.EpiServer.inEditMode;
  }
}
