import { Component, Prop, Mixins } from 'vue-property-decorator';

import LayoutMixin from '@/mixins/LayoutMixin';

@Component({})
export default class SubportalLayout extends Mixins(LayoutMixin) {
  @Prop()
  readonly Header!: string;

  @Prop()
  readonly IsAuthorized: Boolean;
}
