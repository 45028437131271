import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import ImageItem from '@/common/interfaces/ImageItem';
import SizeBreakpoints, {
  BREAKPOINTS,
  ImageSize,
  ImageSizes,
} from '@/common/interfaces/SizeBreakpoints';
import { cloneSimpleObject, sort } from '@/utils/commonUtils';
import { getImageUrl } from '@/utils/imageUtils';

interface ImageSizeWithMinWidth extends ImageSize {
  minWidth: number;
}

const ResponsiveImageComponentProps = Vue.extend({
  props: {
    image: Object as () => ImageItem,
    sizes: Object as () => ImageSizes,
    imageClass: String,
    hover: Boolean,
    imageAnimation: String,
    lazy: { type: Boolean, default: true },
    fitMode: {
      type: String,
      default: 'crop',
    },
    quality: { type: String, default: '50' },
    imgStyle: Object,
  },
});

@Component({})
export default class ResponsiveImageComponent extends ResponsiveImageComponentProps {
  isMounted: boolean = false;
  get sources() {
    const sizes = this.sizes.sizes;
    let result = Object.keys(sizes)
      .map((s) => {
        const size = s as SizeBreakpoints;
        if (sizes[size]) {
          return {
            ...(sizes[size] as ImageSize),
            minWidth: size === 'sm' ? 360 : BREAKPOINTS[size],
          } as ImageSizeWithMinWidth;
        }
        return null;
      })
      .filter((s) => s !== null) as ImageSizeWithMinWidth[];

    return sort(result, (a) => a.minWidth, 'desc');
  }

  get imageClasses() {
    if (this.imageAnimation && this.isMounted) {
      return this.imageClass + ' ' + this.imageAnimation; 
    }
    return this.imageClass;
  }
  
  mounted() {
    this.isMounted = true;
  }

  getImageUrl(size: ImageSize, retina: boolean = true, quality: string = '50') {
    let fitMode: string;

    if (document.body.clientWidth < BREAKPOINTS.sm) {
      fitMode = 'crop'
    } else {
      fitMode = this.fitMode
    }
    if (!this.lazy && size.minWidth == 360) {
      quality = '30'
    }
    return getImageUrl(this.image, size, false, fitMode, quality)
  }

  get imageStyle() {
    if (this.image.FocalPoint) {
      const { x, y } = this.image.FocalPoint;

      return {
        'object-position': `${x}% ${y}%`,
        ...this.imgStyle
      };
    }

    return {
      ...this.imgStyle
    };
  }
}
