enum ToastType {
  Attention,
  Change,
  Danger,
  Error,
  Info,
  New,
  Warning,
}

export default ToastType;
