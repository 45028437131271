import { Component, Vue, Prop } from 'vue-property-decorator';
import Phone from '@/assets/icons/phone.svg?inline';
import { IPhoneCallRibbon, NavigationTopViewModel } from './NavigationTop.types';
import { getComponentProps } from '@/utils/vueUtils';

const NavigationTopProps = Vue.extend({
  props: getComponentProps<NavigationTopViewModel>({
    Number: '1.800.397.3348',
    NumberStr: 'Call 1.800.397.3348',
    Links: [
      {
        Title: 'Order brochure',
        Link: '/test',
      },
      {
        Title: 'Newsletter Signup',
        Link: '/test',
      },
      {
        Title: 'Daily Expedition Reports',
        Link: '/test',
      },
    ],
  }),
});

@Component({
  components: {
    Phone,
  },
})
export default class NavigationTop extends NavigationTopProps {
  @Prop() readonly phone!: IPhoneCallRibbon;
}
