import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import throttle from 'lodash/throttle';

import { RootState } from '@/store/types';

import SizeBreakpoints, { getClosestBreakpoint } from '@/common/interfaces/SizeBreakpoints';

export interface WindowParams {
  size: SizeBreakpoints;
  windowWidth: number;
  offsetY: number;
  offsetPercent: number;
  anchorBarSize: number;
}

export const INIT_WINDOW_WATCHERS = 'INIT_WINDOW_WATCHERS';
export const ON_SCROLL_WINDOW = 'ON_SCROLL_WINDOW';
const actions: ActionTree<WindowParams, RootState> = {
  [INIT_WINDOW_WATCHERS]({ commit, dispatch }) {
    const resize = () => {
      commit(CHANGE_WINDOW_SIZE, window.innerWidth);
    };

    window.addEventListener('resize', throttle(resize, 50));
    resize();

    const scroll = () => dispatch(ON_SCROLL_WINDOW);
    window.addEventListener('scroll', throttle(scroll, 50));
  },

  [ON_SCROLL_WINDOW]({ commit }) {
    commit(CHANGE_PAGE_OFFSET, window.pageYOffset);

    const offsetPercent =
      (document.body.scrollTop + document.documentElement.scrollTop) /
      (document.documentElement.scrollHeight - window.innerHeight);

    commit(CHANGE_PAGE_OFFSET_PERCENT, offsetPercent);
  },
};

export const CHANGE_WINDOW_SIZE = 'changeWindowSize';
export const CHANGE_PAGE_OFFSET = 'changePageOffset';
export const CHANGE_PAGE_OFFSET_PERCENT = 'changePageOffsetPercent';
const mutations: MutationTree<WindowParams> = {
  [CHANGE_WINDOW_SIZE](state, width: number) {
    const newSize = getClosestBreakpoint(width);
    state.windowWidth = width;
    state.anchorBarSize = document.querySelector('.anchor-bar')?.clientHeight || 0;

    if (newSize !== state.size) {
      state.size = newSize;
    }
  },
  [CHANGE_PAGE_OFFSET](state, offset: number) {
    state.offsetY = offset;
  },
  [CHANGE_PAGE_OFFSET_PERCENT](state, percent: number) {
    state.offsetPercent = percent;
  },
};

export const windowSize = 'windowSize';
export const windowWidth = 'windowWidth';
export const isInBreakpoint = 'isInBreakpoint';
export const windowOffset = 'windowOffset';
export const windowOffsetPercent = 'windowOffsetPercent';
export const anchorBarOffset = 'anchorBarOffset';

export interface WindowGetters {
  [windowSize]: SizeBreakpoints;
  [windowWidth]: number;
  [isInBreakpoint]: (breakpoints: SizeBreakpoints[] | SizeBreakpoints) => boolean;
  [windowOffset]: number;
  [windowOffsetPercent]: number;
  [anchorBarOffset]: number;
}

const mapWindowSize: { [key in SizeBreakpoints]: number } = {
  xs: -40,
  sm: -50,
  md: -60,
  lg: -80,
  xl: -80,
  xxl: -80,
};

const getters: GetterTree<WindowParams, RootState> = {
  [windowSize]: (state) => state.size,
  [windowWidth]: (state) => state.windowWidth,
  [isInBreakpoint]: (state) => (breakpoints: SizeBreakpoints[] | SizeBreakpoints) =>
    state.size === breakpoints || breakpoints.indexOf(state.size) !== -1,
  [windowOffset]: (state) => state.offsetY,
  [windowOffsetPercent]: (state) => state.offsetPercent,
  [anchorBarOffset]: (state) => -state.anchorBarSize,
};

export default {
  namespaced: false,
  state: {
    size: 'lg',
    offsetY: 0,
    offsetPercent: 0,
    anchorBarSize: 0,
  },
  actions,
  mutations,
  getters,
} as Module<WindowParams, RootState>;
