import './loadStyles';

import Cookies from 'js-cookie';
import { addWaiter, getGlobalThis, isLocal, scriptToHead } from '@/utils/commonUtils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import {
  GET_PAGE_DATA,
  PageData,
  PageModule,
  SET_MODULE_VISIBLE,
  SET_MODULE_MOUNTED,
  ModuleVisiblityParams,
  GET_HERO,
  GET_TOP_RIBBON,
} from '@/store/modules/modulesLoadModule';
import { anchorBarOffset, HAS_ANCHOR_BAR } from './store/getters';
import { getStore } from './store';
import { ModuleClickedOptionalProps, PagePerformanceCapturedProps, SegmentElement } from './plugins/utm/SegmentElement';
import { ObserveVisibility } from 'vue-observe-visibility';
import { GOOGLE_DENIED_PARAMS, GOOGLE_GRANTED_PARAMS, startConsentManager } from './plugins/utm/SegmentConsentManager';
import { fetchSegmentData } from './plugins/utm/SegmentConsentManager/utils';
import { isInUSTimezone } from 'in-us'
import MainLayout from '@/layouts/MainLayout/MainLayout.vue'
import SubportalLayout from '@/layouts/SubportalLayout/SubportalLayout.vue'
import TwoColumnLayout from '@/layouts/TwoColumnLayout/TwoColumnLayout.vue'
import SkipLink from '@/components/SkipLink/SkipLink.vue';

const global = getGlobalThis() as any;

@Component({
  components: {
    MainLayout,
    SubportalLayout,
    TwoColumnLayout,
    SkipLink,
  },
  directives: {
    ObserveVisibility
  },
})
export default class App extends Vue {
  @Getter(GET_PAGE_DATA)
  PageData: PageData;

  VisibleModules: string[] = [];

  newsletterPopupKey: string = 'news'

  get layout() {
    return this.PageData.Layout;
  }
  get layoutParams() {
    return this.PageData.LayoutParams;
  }
  get slots() {
    return this.PageData.Slots;
  }

  @Getter(GET_HERO) Hero;
  get hasHero() {
    return !!this.Hero();
  }

  @Getter(GET_TOP_RIBBON) Ribbon;
  get hasRibbon() {
    return this.Ribbon();
  }

  @Getter(anchorBarOffset) anchorBarOffset: number;
  @Getter(HAS_ANCHOR_BAR) hasAnchorBar: boolean;

  getVisibilityIOOptions(module: PageModule) {
    const anchorBarOffset = this.hasAnchorBar ? this.anchorBarOffset : 0;
    const intersectionParams: IntersectionObserverInit = {
      threshold: [0.2],
      rootMargin: `${anchorBarOffset}px 0px 0px 0px`,
    };

    return {
      callback: (isVisible, entry) => this.visibilityChanged(module, isVisible, entry),
      throttle: 600,
      intersection: intersectionParams,
    };
  }

  @Mutation(SET_MODULE_VISIBLE) setVisible: (params: ModuleVisiblityParams) => void;
  @Mutation(SET_MODULE_MOUNTED) setMounted: (module: PageModule) => void;

  visibilityChanged(module: PageModule, isVisible: boolean, entry: IntersectionObserverEntry) {
    if (module.Id !== 'SSRFixModule' && module.Id !== 'Back to Top') {
      if (isVisible && !this.VisibleModules.includes(module.Id)) {
        this.VisibleModules.push(module.Id);
        this.setVisible({ Module: module, IsVisible: isVisible });
        SegmentElement.sendEvent('moduleViewed', {
          module_type_id: module.Id,
          module_type_name: module.Type, 
          nonInteraction: 1, 
        })
      }
    }
  }

  modulesCount = 0;
  mountedModulesCount = 0;

  moduleMounted(module: PageModule) {
    this.mountedModulesCount++
    this.setMounted(module);
  }

  @Watch('mountedModulesCount')
  mountedModulesCountUpdate(count: number) {
    if (count === this.modulesCount) {
      document.body.addEventListener('click', SegmentElement.addButtonTracking);
      document.body.addEventListener('contextmenu', SegmentElement.addButtonTracking);
      document.querySelectorAll(SegmentElement.getTrackableLinks()).forEach(SegmentElement.addLinkTracking);
      setTimeout(() => this.setPageScrolledEvent(), 1000);
      window.analytics?.ready?.(() => {
        if (!Cookies.get('Segment.AnonymousId')) {
          Cookies.set('Segment.AnonymousId', window.analytics.user?.().anonymousId(), { expires: 1 })
        }
      })
    }
  }

  async initializeConsentManager() {
    let writeKey: string;
    let consentCookieName: string

    if (global.location.host.includes('dev2019')) {
      consentCookieName = 'tracking-preferences-dev'
      writeKey = 'CZYamFxduekOKeLKKSw5ZEXH9xmef34M'
    } else if (global.location.host.includes('stg2019')) {
      consentCookieName = 'tracking-preferences-stg'
      writeKey = 'Y9PKgl5cGPC9mmz2FRDfP5RhBiR6ch0n'
    } else {
      consentCookieName = 'tracking-preferences'
      writeKey = 'ZDCQEz3o0EzrLkgHRcERWmLx1HQh61og'
    }

    if (process.env.NODE_ENV === 'development') {
      writeKey = 'Y9PKgl5cGPC9mmz2FRDfP5RhBiR6ch0n'
      // load segment snippet for local
      scriptToHead(`!function () { var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else { analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; analytics._writeKey = '${writeKey}'; analytics.SNIPPET_VERSION = "4.13.2";}}();`)
    }

    const isUSA = isInUSTimezone()

    global.analytics.ready(function() {
      const cookie = Cookies.getJSON(consentCookieName)
      const isMarketing = cookie && cookie.custom && cookie.custom['Marketing and Analytics']
      const isUSnotInteracted = !cookie && isUSA
      if (global.gtag) {
        if (isMarketing || isUSnotInteracted) {
          global.gtag('consent', 'default', GOOGLE_GRANTED_PARAMS)
        } else {
          global.gtag('consent', 'default', GOOGLE_DENIED_PARAMS)
        }
      }
    })

    const { categories, content } = await fetchSegmentData(writeKey, isUSA)
    startConsentManager(writeKey, content, categories, isUSA, consentCookieName, this.rerenderNewsletterPopup)
  }

  fixAudioEyeIframe() {
    // AudioEye frameTitleNotFound
    addWaiter(
      () => document.querySelector('.grecaptcha-badge + iframe[style="display: none;"]') && !document.querySelector('.grecaptcha-badge + iframe[style="display: none;"]').getAttribute('title'),
      () => {
        document.querySelector('.grecaptcha-badge + iframe[style="display: none;"]').setAttribute('title', 'reCAPTCHA empty iframe')
      },
      2000,
    );
  }

  setReferringUrl(utm_params) {
    const domain = 'expeditions.com';
    const excludedDomains = ['https://www.voyagedocuments.com/', 'https://www.expeditiondocuments.com/', 'https://www.voyageportfolio.com/'];
    const ref = document.referrer;
    const referring_domain = ref && !ref.includes(domain) && !excludedDomains.includes(ref) ? ref : null;
    if (referring_domain) {
      Cookies.set('referring_domain', referring_domain , { expires: 30, domain: !isLocal() ? `.${domain}` : null });
    }

    const refIncludesNG = ref.includes('disney') || ref.includes('nationalgeographic')
    if (refIncludesNG || utm_params.get('utm_source') === 'natgeo') {
      Cookies.set('isNatGeoGuest', true , { expires: 30, domain: !isLocal() ? `.${domain}` : null });
    }
  }

  isMounted: boolean = false;
  mounted() {
    const utm_params = new URLSearchParams(window.location.search)
    this.fixAudioEyeIframe()
    this.isMounted = true;
    this.modulesCount = this.slots.reduce((total, slot) => total + slot.Modules.length, 0);

    this.setReferringUrl(utm_params)

    this.initializeConsentManager()

    const properties = getStore().state.Meta.Analytics;
    SegmentElement.page(properties);

    if (!Cookies.get('utm_obj') && window.location.search.includes('utm_')) {
      let utm_obj = {};
      if (utm_params.has('utm_campaign')) {
        utm_obj['name'] = utm_params.get('utm_campaign');
      }
      
      for (let value of utm_params.keys()) {
        if (value.includes('utm_'))
          utm_obj[value.replace('utm_', '')] = utm_params.get(value);
      }
      
      Cookies.set('utm_obj', utm_obj, { expires: 1 });
    }

    const email_md5 = new URLSearchParams(window.location.search).get('email_md5')
    const userId = Cookies.get('ajs_user_id') || undefined
    if (email_md5) {
      SegmentElement.identify(userId, {email_md5})
      Cookies.set('email_md5', email_md5, {expires: 30})
    }

    this.fixAudioEyeFormFieldLabelNotFound()
  }

  rerenderNewsletterPopup() {
    this.newsletterPopupKey = this.newsletterPopupKey + Date.now()
  }

  performanceData: PagePerformanceCapturedProps = {};
  collectPerformanceData({name, value, entries}) {
    const formattedValue = Math.round(value * 1e3) / 1e3;
    if (name === 'FID') this.performanceData.page_fid = formattedValue;
    if (name === 'LCP') this.performanceData.page_lcp = formattedValue;
    if (name === 'FCP') this.performanceData.page_fcp = formattedValue;
    if (name === 'TTFB') this.performanceData.page_ttfb = formattedValue;
    if (name === 'CLS') {
      this.performanceData.page_cls = formattedValue;
      this.performanceData.count_cls = entries.length;
    }
  }

  sendPerformanceData() {
    const { page_type_id, page_type_name } = getStore().state.Meta.Analytics;
    const navigator = window.navigator as any;
    SegmentElement.sendEvent('pagePerformanceCaptured', {
      ...this.performanceData,
      ...(navigator.connection && { connection_type: navigator.connection.effectiveType }),
      nonInteraction: 1, page_type_id, page_type_name
    })
  }

  fixAudioEyeFormFieldLabelNotFound() {
    let label = document.createElement('label'); 
    label.innerText = label.htmlFor = 'g-recaptcha-response';
    label.style.display = 'none'
    document.body.appendChild(label)
  }

  moduleClicked(props: ModuleClickedOptionalProps, module: PageModule) {
    SegmentElement.sendEvent('moduleClicked', {
      ...props,
      module_type_id: module.Id,
      module_type_name: module.Type,
    })
  }

  setPageScrolledEvent() {
    let scrollVal = 0;
    
    let is25 = false;
    let is50 = false;
    let is75 = false;
    let is100 = false;

    const { products: analyticsProducts, page_type_name } = getStore().state.Meta.Analytics;
    let products;
    if (page_type_name === 'ItineraryLandingPage') products = analyticsProducts.map(p => ({name: p.name}));
    const pageScrolled = (depth: number) => SegmentElement.sendEvent('pageScrolled', {
      depth, nonInteraction: 1,
      ...(products && {products}),
    });

    window.addEventListener('scroll', () => {
      let docHeight = document.body.offsetHeight;
      let winHeight = window.innerHeight;
      let scrollTop = window.scrollY;
      if (scrollVal < scrollTop) {
        let scrollPercent = Math.round(scrollTop / (docHeight - winHeight) * 100);
        if (scrollPercent >= 25 && !is25)  {
          is25 = true;
          pageScrolled(25);
        }
        if (scrollPercent >= 50 && !is50)  {
          is50 = true;
          pageScrolled(50);
        }
        if (scrollPercent >= 75 && !is75)  {
          is75 = true;
          pageScrolled(75);
        }
        if (scrollPercent == 100 && !is100)  {
          is100 = true;
          pageScrolled(100);
        }
      }
      scrollVal = window.scrollY;
    });
  }
}
