import { Component, Mixins } from 'vue-property-decorator';

import LayoutMixin from '@/mixins/LayoutMixin';
import NavigationTop from '@/components/NavigationTop/NavigationTop.vue';
import { GET_NAVIGATION } from '@/store/modules/modulesLoadModule';
import { Getter } from 'vuex-class';

@Component({components: {
    NavigationTop
}})

export default class MainLayout extends Mixins(LayoutMixin) {
    @Getter(GET_NAVIGATION) Navigation;
    get nav() {
      return this.Navigation()
    }
}
