const messages = {
  footer: {
    phone: '@:header.phoneToCall | 1.212.261.9000',
    phoneCall: '1.212.261.9000',
  },
  header: {
    phoneToCall: '1.800.397.3348',
    contactPhone: '@:header.phoneToCall',
    contactPhoneTitle: '@:header.contactPhone',
    contactSubPhone: '@:header.phoneToCall',
    contactSubPhoneShort: '@:header.shortPhone',
    shortPhone: '+@:header.phoneToCall',
    navigationPhoneString: 'Call {link}',
    reservationPhoneString: '{phone}',
  }
}
export default messages
