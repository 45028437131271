export const generateRandomString = (length: number = 10) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export function getNumberFromPrice(price: string) {
  return parseFloat(price.replace(/\D/g, ''));
}

const escapeRegExp = (s: string) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const replaceAll = (value: string, search: string | RegExp, replacement: string): string => {
  if (!value) {
    return value;
  }

  const s = typeof search === 'string' ? new RegExp(escapeRegExp(search), 'g') : search;

  return value.replace(s, replacement);
};

export const replaceAllByArrays = (value: string, search: string[], replacement: string[]) => {
  if (!value) {
    return value;
  }

  let result = value;
  for (let i = 0; i < search.length; i++) {
    result = replaceAll(result, search[i], replacement[i]);
  }
  return result;
};

export const addLeadingZeros = (
  initialValue: number | string,
  showBeforeValue: number = 10,
  leadingSymbol: string = '0',
): string => {
  if (!initialValue) {
    throw new Error('Initial value is required!');
  }

  if (+initialValue < showBeforeValue) return leadingSymbol + initialValue;
  return initialValue.toString();
};

export const stripHtml = (s: string) => s?.replace(/(<([^>]+)>)/gi, '');

export const spacesToDashesLowerCase = (s: string) => s.replace(/\s+/g, '-').toLowerCase();