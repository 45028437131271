import VueI18n from 'vue-i18n';

import { PageMeta, BusinessContextType } from '@/store/modules/metadataModule';
import messages from '@/lang';

export function getI18n(meta: PageMeta) {
  // Create VueI18n instance with options
  let culture = meta.Culture as string;
  if (meta.BusinessContext == BusinessContextType.Hyatt) culture = 'en-HYATT';

  return new VueI18n({
    locale: culture, // set locale
    fallbackLocale: 'en-US',
    messages, // set locale messages
  });
}
