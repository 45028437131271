const messages = {
  footer: {
    phone: '1300 361 012'
  },
  header: {
    phoneToCall: '1300.361.012',
    contactPhone: '@:footer.phone(AU)',
    contactPhoneTitle: '@:header.contactPhone | @:header.contactSubPhone',
    contactSubPhone: '0800 444 462(NZ)',
    contactSubPhoneShort: '0800.444.462',
    shortPhone: '@:header.phoneToCall',
    navigationPhoneString: 'Call {link}(AU) | {sublink}(NZ)',
    reservationPhoneString: '{phone} {subphone}',
  }
}
export default messages
