import Vue from 'vue';

import './filters';

import AnimateScroll from 'vue-animate-scroll';
Vue.use(AnimateScroll);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import UUIDPlugin from './uuidPlugin';
Vue.use(UUIDPlugin);

// import VueUuid from 'vue-unique-id';
// Vue.use(VueUuid);

import ClickOutside from 'v-click-outside';
Vue.use(ClickOutside);

import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

// import VueObserveVisibility from 'vue-observe-visibility';
// Vue.use(VueObserveVisibility);

import VueMasonry from 'vue-masonry-css';
Vue.use(VueMasonry);

import SegmentPlugin from './segmentPlugin';
Vue.use(SegmentPlugin);

import lazySizes from 'lazysizes';
lazySizes.init();

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
