import Vue from 'vue';
import { format, parseISO } from 'date-fns';
import { sum, isSSR } from '@/utils/commonUtils';

export function dateFilter(value: string, formatString: string = 'MM/dd/yyyy') {
  if (value === '1900-01-01T00:00:00') return '';

  return format(parseISO(value), formatString);
}
Vue.filter('date', dateFilter);

let formatter: any;
try {
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
  } as any);
} catch (e) {
  console.log(e)
}

export function priceFilter(value: number, params?: { hideZeroValue: boolean }) {
  if (params?.hideZeroValue && value == 0) return '';
  if (formatter) {
    return formatter.format(value);
  } else {
    return value;
  }
}
Vue.filter('price', priceFilter);

export function percentFilter(value: number, params?: { hideZeroValue: boolean }) {
  if (params?.hideZeroValue && value == 0) return '';

  return Number(value).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
}
Vue.filter('percent', percentFilter);

Vue.filter('sum', function(amountArray: number[]) {
  return sum(amountArray);
});

Vue.filter('ssr', function(original: string, replacement: string = 'div') {
  return isSSR() ? replacement : original;
});
