import { getGlobalThis } from './../../utils/commonUtils';
/**
 * The module responsible to handling Episerver specific state that is relevant
 * when editing content in edit mode.
 */

// mutation for the epiContext module
// export const UPDATE_CONTEXT = 'epiContext/UPDATE_CONTEXT';

// const state = {
//     inEditMode: false,
//     isEditable: false
// };

// const mutations = {
//     [UPDATE_CONTEXT](state, newContext) {
//         state.isEditable = newContext.isEditable;
//         state.inEditMode = newContext.inEditMode;
//     }
// };

// export default {
//     state,
//     mutations
// };

import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';

import { RootState } from '@/store/types';

export interface EpiServerContext {
  isEditable: boolean;
  inEditMode: boolean;
}

export const UPDATE_EPI_CONTEXT = 'UPDATE_EPI_CONTEXT';

const mutations: MutationTree<EpiServerContext> = {
  [UPDATE_EPI_CONTEXT]: (state, newContext: EpiServerContext) => {
    state.isEditable = newContext.isEditable;
    state.inEditMode = newContext.inEditMode;
  },
};

export default {
  namespaced: false,
  state: { isEditable: false, inEditMode: false },
  mutations,
} as Module<EpiServerContext, RootState>;

export const runEpiServerWatch = (store: Store<RootState>) => {
  //console.log('run EPI server watch');
  const globalThis = getGlobalThis();

  function setContext() {
    // The `epiReady` event only has `isEditable`, but the epi object has both.
    const context = {
      inEditMode: globalThis.epi.inEditMode,
      isEditable: globalThis.epi.isEditable,
    };
    //console.log('set context', context, 'epi:', globalThis.epi);

    // Make the context available to all Vue components.
    store.commit(UPDATE_EPI_CONTEXT, context);

    // If we're in an editable context we want to update the model on every change by the editor
    if (globalThis.epi.isEditable) {
      globalThis.epi.subscribe('contentSaved', (message) => {
        //console.log('content saved', message);
        // store.dispatch(UPDATE_MODEL_BY_CONTENT_LINK, message.contentLink);
      });
    }
  }

  globalThis.addEventListener('load', () => {
    // Expect `epi` to be there after the `load` event. If it's not then we're
    // not in any editing context.
    if (!globalThis.epi) {
      return;
    }

    // Check that ready is an actual true value (not just truthy).
    if (globalThis.epi.ready === true) {
      // `epiReady` already fired.
      setContext();

      // The subscribe method won't be available in View mode.
    } else if (globalThis.epi.subscribe) {
      // Subscribe if the `epiReady` event hasn't happened yet.
      globalThis.epi.subscribe('epiReady', () => setContext());
    }
  });

  //console.log(!!globalThis.epi ? 'EPI' : 'NO EPI');
  
  return !!globalThis.epi?.inEditMode;
};
