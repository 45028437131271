const messages = {
    footer: {
      phone: '@:header.phoneToCall'
    },
    header: {
      phoneToCall: '1.212.765.7740',
      contactPhone: '@:header.phoneToCall',
      contactPhoneTitle: '@:header.contactPhone',
      contactSubPhone: '@:header.phoneToCall',
      shortPhone: '+@:header.phoneToCall',
      navigationPhoneString: 'Call {link}',
      reservationPhoneString: '{phone}',
    }
  }
  export default messages
