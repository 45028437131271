import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import SizeBreakpoints, {
  ImageWidths,
  ImageSizes,
  ImageSize,
  ImageSizesObject,
} from '@/common/interfaces/SizeBreakpoints';
import ImageItem from '@/common/interfaces/ImageItem';

type RatioType = '3x2' | '2x3' | '16x9' | '1x1';

const ResponsiveImageRatioProps = Vue.extend({
  props: {
    image: Object as () => ImageItem,
    widths: Object as () => ImageWidths,
    imageClass: String,
    ratio: String as () => RatioType,
    hover: Boolean,
    quality: String,
    fitMode: {
      type: String,
      default: 'crop',
    }
  },
});

@Component({
  components: {
    ResponsiveImage,
  },
})
export default class ResponsiveImageRatioComponent extends ResponsiveImageRatioProps {
  get sizes(): ImageSizes {
    return {
      def: new ImageSize([this.widths.def, this.resizedWidth(this.widths.def)]),
      sizes: (Object.keys(this.widths.sizes) as SizeBreakpoints[]).reduce((result, key) => {
        const size = this.widths.sizes[key];
        if (size) {
          result[key] = new ImageSize([size, this.resizedWidth(size as number)]);
        }
        return result;
      }, {} as ImageSizesObject),
    };
  }

  get resizeRatio() {
    switch (this.ratio) {
      case '3x2':
        return 2 / 3;
      case '2x3':
        return 3 / 2;
      case '16x9':
        return 9 / 16;
      case '1x1':
        return 1;
    }
  }

  resizedWidth(num: number): number {
    return Math.ceil(num * this.resizeRatio);
  }
}
