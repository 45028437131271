/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
// @ts-nocheck

import Ajv from 'ajv'
import * as Segment from './segment'

export interface AnsweredNudge {
	/**
	 * User's answer in response to Qualaroo question
	 */
	answer?: string
	/**
	 * Qualaroo nudge ID
	 */
	nudge_id?: string
	/**
	 * Qualaroo nudge name
	 */
	nudge_name?: string
	/**
	 * Qualaroo question
	 */
	question?: string
}
export interface ButtonClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The text value of a button or rich text link
	 */
	text: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page.
	 */
	url: string
}
export interface ChatOpened {
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface ChatRequested {
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface CheckoutExited {
	checkout_id?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Order/transaction ID
	 */
	order_id?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step?: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name?: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total?: number
	/**
	 * The text value of a button or rich text link
	 */
	text?: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page
	 */
	url: string
}
export interface CabinsItem {
	/**
	 * The human readable description of a cabin category
	 */
	cabin_category_description?: string
	/**
	 * The database id of a cabin category
	 */
	cabin_category_id?: string
	/**
	 * The number of adult occupants of a cabin
	 */
	passenger_adult_count?: number
	/**
	 * The number of child occupants of a cabin
	 */
	passenger_child_count?: number
	/**
	 * Per person price ($) of the cabin
	 */
	price?: number
}
export interface CategoriesItem {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string
}
export interface OffersItem {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface CheckoutStarted {
	/**
	 * ID associated with the booking
	 */
	booking_number?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * The number of cabins selected by the user
	 */
	cabin_quantity?: number
	/**
	 * Cabins available on a ship
	 */
	cabins?: CabinsItem[]
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem[]
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name?: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem[]
	/**
	 * Order/transaction ID
	 */
	order_id?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Total number of passengers associated with the booking
	 */
	passenger_total_count?: number
	/**
	 * Port of departure
	 */
	port_of_departure?: string
	/**
	 * Human readable name for the previous page that the user was on
	 */
	previous_page_name?: string
	/**
	 * Price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id?: string
	/**
	 * Total value of the order
	 */
	revenue?: number
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step?: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total?: number
	/**
	 * The total length of the trip (in days)
	 */
	trip_length?: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface CabinsItem1 {
	/**
	 * The human readable description of a cabin category
	 */
	cabin_category_description?: string
	/**
	 * The database id of a cabin category
	 */
	cabin_category_id?: string
	/**
	 * The number of adult occupants of a cabin
	 */
	passenger_adult_count?: number
	/**
	 * The number of child occupants of a cabin
	 */
	passenger_child_count?: number
	/**
	 * Per person price ($) of the cabin
	 */
	price?: number
}
export interface CategoriesItem1 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string
}
export interface OffersItem1 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface CheckoutStepCompleted {
	/**
	 * ID associated with the booking
	 */
	booking_number?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * The number of cabins selected by the user
	 */
	cabin_quantity?: number
	/**
	 * Cabins available on a ship
	 */
	cabins?: CabinsItem1[]
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem1[]
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date?: string
	/**
	 * Total amount of deposit required to secure reservation
	 */
	deposit_amount?: number
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name?: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem1[]
	/**
	 * Order/transaction ID
	 */
	order_id?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Total number of passengers associated with the booking
	 */
	passenger_total_count?: number
	/**
	 * Port of departure
	 */
	port_of_departure?: string
	/**
	 * Price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id?: string
	/**
	 * Total value of the order
	 */
	revenue?: number
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total?: number
	/**
	 * The total length of the trip (in days)
	 */
	trip_length?: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface CabinsItem2 {
	/**
	 * The human readable description of a cabin category
	 */
	cabin_category_description?: string
	/**
	 * The database id of a cabin category
	 */
	cabin_category_id?: string
	/**
	 * The number of adult occupants of a cabin
	 */
	passenger_adult_count?: number
	/**
	 * The number of child occupants of a cabin
	 */
	passenger_child_count?: number
	/**
	 * Per person price ($) of the cabin
	 */
	price?: number
}
export interface CategoriesItem2 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string
}
export interface OffersItem2 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface CheckoutStepViewed {
	/**
	 * ID associated with the booking
	 */
	booking_number?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * The number of cabins selected by the user
	 */
	cabin_quantity?: number
	/**
	 * Cabins available on a ship
	 */
	cabins?: CabinsItem2[]
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem2[]
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date?: string
	/**
	 * Total amount of deposit required to secure reservation
	 */
	deposit_amount?: number
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name?: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem2[]
	/**
	 * Order/transaction ID
	 */
	order_id?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Total number of passengers associated with the booking
	 */
	passenger_total_count?: number
	/**
	 * Port of departure
	 */
	port_of_departure?: string
	/**
	 * Price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id?: string
	/**
	 * Total value of the order
	 */
	revenue?: number
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total?: number
	/**
	 * The total length of the trip (in days)
	 */
	trip_length?: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface ErrorReceived {
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * User's captcha score
	 */
	captcha_score?: number
	/**
	 * Backend context around what prompted the error message
	 */
	error_context?: string
	/**
	 * Error message that the front-end user sees
	 */
	error_message?: string
	/**
	 * Source system of the error message
	 */
	error_source?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface ExperimentViewed {
	/**
	 * The experiment's audience id
	 */
	audienceId?: string | null
	/**
	 * The experiment's audience name
	 */
	audienceName?: string | null
	/**
	 * The experiment's campaign id
	 */
	campaignId?: string
	/**
	 * The experiment's campaign name
	 */
	campaignName?: string
	/**
	 * The experiment's id
	 */
	experimentId?: string
	/**
	 * The experiment's human readable name
	 */
	experimentName?: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * The variation id
	 */
	variationId?: string
	/**
	 * The variation human readable name
	 */
	variationName?: string
}
export interface FiltersItem {
	/**
	 * id of the filter type that the customer is using
	 */
	type: string
	/**
	 * id of the selection that the customer chose
	 */
	value: string
}
export interface FiltersApplied {
	/**
	 * Product or content filters that the customer is using

	 */
	filters: FiltersItem[]
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface FormStepCompleted {
	/**
	 * The database id of a form
	 */
	form_id: string
	/**
	 * The human readable name of a form
	 */
	form_name: string
	/**
	 * The type of form (newsletter, brochure request, DER)
	 */
	form_type: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total: number
}
export interface FormStepViewed {
	/**
	 * The database id of a form
	 */
	form_id: string
	/**
	 * The human readable name of a form
	 */
	form_name: string
	/**
	 * The type of form (newsletter, brochure request, DER)
	 */
	form_type: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total: number
}
export interface FormSubmissionAttempted {
	/**
	 * The database id of a form
	 */
	form_id?: string
	/**
	 * The human readable name of a form
	 */
	form_name?: string
	/**
	 * The type of form (newsletter, brochure request, DER)
	 */
	form_type?: string
	/**
	 * Descriptive label of an event
	 */
	label?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface FormSubmitted {
	/**
	 * Age group categorization as selected by a user (ex. 18 & under, 19 - 45, etc.)
	 */
	age_group?: string
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * The database id of a brochure
	 */
	brochure_id?: string
	/**
	 * The human readable name of a brochure
	 */
	brochure_name?: string
	/**
	 * User's captcha score
	 */
	captcha_score?: number
	/**
	 * The database id of a form
	 */
	form_id?: string
	/**
	 * The human readable name of a form
	 */
	form_name: string
	/**
	 * The type of form (newsletter, brochure request, DER)
	 */
	form_type: string
	/**
	 * Descriptive label of an event
	 */
	label?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name?: string
	/**
	 * How a user was referred to the site as selected by a user (ex. Received a Brochure, Received an Email, etc.)
	 */
	referral_source?: string
	/**
	 * Topics of interest as selected by a user
	 */
	topic_interests?: string[]
	/**
	 * Database ID for the web transaction (e.g. form submission)
	 */
	transaction_id?: number
	/**
	 * Travel preference of the guest, e.g. travel solo, with guests, etc.
	 */
	travel_preference?: string
}
export interface FormViewed {
	/**
	 * The database id of a form
	 */
	form_id: string
	/**
	 * The human readable name of a form
	 */
	form_name: string
	/**
	 * The type of form (newsletter, brochure request, DER)
	 */
	form_type: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface GlobalNavigationClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Navigation category, i.e. main navigational category that user clicked through such as 'Destinations', 'Ships', etc
	 */
	navigation_category?: string
	/**
	 * Whether user clicked on element in the 'header' or 'footer'
	 */
	navigation_level: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The text value of a button or rich text link
	 */
	text: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
	 */
	url: string
}
export interface LinkClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Style of the link, e.g. rich text, button, etc
	 */
	link_style?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The text value of a button or rich text link
	 */
	text: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page.
	 */
	url: string
}
export interface ModuleClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * The database ID of the component
	 */
	component_id?: string
	/**
	 * The human readable name of the component
	 */
	component_name?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string | null
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Section of the page, e.g. 'highlights', 'day by day', etc.
	 */
	page_section?: string
	/**
	 * Position in the list (ex. 3)
	 */
	position?: number
	/**
	 * The text value of a button or rich text link
	 */
	text?: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page
	 */
	url?: string
}
export interface ModuleClosed {
	/**
	 * The database id of a module
	 */
	module_type_id?: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface ModuleInteraction {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * The human readable name of the component
	 */
	component_name?: string
	/**
	 * The count of components within a given module
	 */
	count_components?: number
	/**
	 * The type of module interaction
	 */
	interaction_type?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string | null
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Section of the page, e.g. 'highlights', 'day by day', etc.
	 */
	page_section?: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id?: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name?: string
	/**
	 * Position in the product list (ex. 3)
	 */
	position?: number
	/**
	 * The text value of a button or rich text link
	 */
	text?: string
}
export interface ModuleOpened {
	/**
	 * The database id of a module
	 */
	module_type_id?: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface ModuleViewed {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string | null
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
}
export interface CabinsItem3 {
	/**
	 * The human readable description of a cabin category
	 */
	cabin_category_description?: string
	/**
	 * The database id of a cabin category
	 */
	cabin_category_id?: string
	/**
	 * The number of adult occupants of a cabin
	 */
	passenger_adult_count?: number
	/**
	 * The number of child occupants of a cabin
	 */
	passenger_child_count?: number
	/**
	 * Per person price ($) of the cabin
	 */
	price?: number
}
export interface CategoriesItem3 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string
}
export interface OrderCompleted {
	/**
	 * ID associated with the booking
	 */
	booking_number?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * The number of cabins selected by the user
	 */
	cabin_quantity?: number
	/**
	 * Cabins available on a ship
	 */
	cabins?: CabinsItem3[]
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem3[]
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date?: string
	/**
	 * Total amount of deposit required to secure reservation
	 */
	deposit_amount?: number
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Total number of passengers associated with the booking
	 */
	passenger_total_count?: number
	/**
	 * Payment method chosen
	 */
	payment_method?: string
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id?: string
	/**
	 * Total value of the order
	 */
	revenue?: number
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Whether event was triggered via a server-side source as opposed to JS
	 */
	server_side?: boolean
	/**
	 * The database id of a ship
	 */
	ship_id?: string
	/**
	 * The human readable name of a ship
	 */
	ship_name?: string
	/**
	 * The total length of the trip (in days)
	 */
	trip_length?: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface PagePerformanceCaptured {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Type of connection the user has, e.g. 4g, wifi, etc
	 */
	connection_type?: string
	/**
	 * Count of cumulative layout shifts
	 */
	count_cls?: number
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Cumulative Layout Shift (CLS) metric of the page
	 */
	page_cls?: number
	/**
	 * First Contentful Paint (FCP) metric of the page
	 */
	page_fcp?: number
	/**
	 * First Input Delay (FID) metric of the page
	 */
	page_fid?: number
	/**
	 * Largest Contentful Paint (LCP) of the page
	 */
	page_lcp?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Time to First Byte (TTFB) of the page
	 */
	page_ttfb?: number
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name: string
}
export interface ProductsItem {
	/**
	 * Name of the product being viewed
	 */
	name?: string
}
export interface PageScrolled {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * The quarterly interval / heartbeat for scroll depth of a page or screen
	 */
	depth: number
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Products displayed in the product list
	 */
	products?: ProductsItem[]
}
export interface BrochuresItem {
	/**
	 * The database id of a brochure
	 */
	brochure_id?: string
	/**
	 * The human readable name of a brochure
	 */
	brochure_name?: string
}
export interface CategoriesItem4 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string
}
export interface ProductsItem1 {
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product being viewed
	 */
	name?: string
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id?: string
}
export interface ShipsItem {
	/**
	 * The database id of a ship
	 */
	ship_id?: string
	/**
	 * The human readable name of a ship
	 */
	ship_name?: string
}
export interface TeamMembersItem {
	/**
	 * The database id of a team member
	 */
	team_member_id?: string
	/**
	 * The name of a team member
	 */
	team_member_name?: string
	/**
	 * The human readable role name of a team member
	 */
	team_member_role?: string
}
export interface PageViewed {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * Brochures associated with the product or content
	 */
	brochures?: BrochuresItem[]
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem4[]
	/**
	 * Value of anchor tag that deep links user to specific content within a page, e.g. within page call for https://www.expeditions.com/destinations/belize-and-guatemala#daily-expedition-reports we would pass 'daily-expedition-reports' as the deep_link value
	 */
	deep_link?: string
	/**
	 * Whether page viewed is the user's landing page (i.e. where they entered the site either directly or from another domain)
	 */
	is_landing: boolean
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Human readable name for the page
	 */
	name: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id?: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name?: string
	/**
	 * Path portion of the URL of the page. Equivalent to canonical path which defaults to location.pathname from the DOM API
	 */
	path: string
	/**
	 * Products displayed in the product list
	 */
	products?: ProductsItem1[]
	/**
	 * Full URL of the previous page. Equivalent to document.referrer from the DOM API
	 */
	referrer: string
	/**
	 * Query string portion of the URL of the page. Equivalent to location.search from the DOM API
	 */
	search: string
	/**
	 * Ships associated with the product or content
	 */
	ships?: ShipsItem[]
	/**
	 * Array of tag categories applied
	 */
	tags_applied?: string[]
	/**
	 * Array of audience tags applied to content
	 */
	tags_audience?: any[]
	/**
	 * Array of trip type tags applied to content
	 */
	tags_content_pillar?: any[]
	/**
	 * Array of content type tags applied to content
	 */
	tags_content_type?: any[]
	/**
	 * Array of destination tags applied to content
	 */
	tags_destination?: any[]
	/**
	 * Array of funnel tags applied to content
	 */
	tags_funnel?: any[]
	/**
	 * Array of geography tags applied to content
	 */
	tags_geographies?: any[]
	/**
	 * Array of trip type tags applied to content
	 */
	tags_interest_activity?: any[]
	/**
	 * Array of itinerary tags applied to content
	 */
	tags_itinerary?: any[]
	/**
	 * Array of promotions tags applied to content
	 */
	tags_promotions?: any[]
	/**
	 * array of series / campaign tags applied to content
	 */
	tags_series_campaign?: any[]
	/**
	 * Array of ship tags applied to content
	 */
	tags_ship?: any[]
	/**
	 * Array of source tags applied to content
	 */
	tags_source?: any[]
	/**
	 * Array of trip type tags applied to content
	 */
	tags_trip_type?: any[]
	/**
	 * Team members associated with the product or page
	 */
	team_members?: TeamMembersItem[]
	/**
	 * Title of the page. Equivalent to document.title from the DOM API
	 */
	title: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
	 */
	url: string
}
export interface PaymentInfoEntered {
	/**
	 * Brand associated with the product or content
	 */
	brand?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Number representing a step in the checkout process or a form flow
	 */
	step?: number
	/**
	 * Human readable name / title of a step in a multi-stage checkout or form flow
	 */
	step_name?: string
	/**
	 * The total number of steps in a multi-step checkout or form flow
	 */
	step_total?: number
}
export interface PhoneNumberClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Phone number
	 */
	phone: string
}
export interface CategoriesItem5 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category: string
}
export interface OffersItem3 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface ProductClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Brand associated with the product or content
	 */
	brand: string
	/**
	 * Categories associated with the product or page
	 */
	categories?: CategoriesItem5[]
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date?: string
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the targeted Algolia index. Maps to index in the Insights event payload
	 */
	index?: string
	/**
	 * Product or content list being viewed (analogous to Destination id)
	 */
	list_id?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * The database id of a module
	 */
	module_type_id?: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name: string
	/**
	 * The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
	 */
	objectID?: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem3[]
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id?: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name?: string
	/**
	 * Port of departure
	 */
	port_of_departure?: string
	/**
	 * Position in the product list (ex. 3)
	 */
	position?: number
	/**
	 * Price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id: string
	/**
	 * Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload
	 */
	queryID?: string
	/**
	 * The total length of the trip (in days)
	 */
	trip_length?: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface FiltersItem1 {
	/**
	 * id of the filter type that the customer is using
	 */
	type: string
	/**
	 * id of the selection that the customer chose
	 */
	value: string
}
export interface CategoriesItem6 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category: string
}
export interface OffersItem4 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface ProductsItem2 {
	/**
	 * Brand associated with the product or content
	 */
	brand: string
	/**
	 * Categories associated with the product or page
	 */
	categories: CategoriesItem6[]
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product being viewed
	 */
	name: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem4[]
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id: string
}
export interface SortsItem {
	/**
	 * id of the sort type that the customer is using
	 */
	type?: string
	/**
	 * id of the selection type the customer is using (ascending, descending)
	 */
	value?: string
}
export interface ProductListFiltered {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string | null
	/**
	 * Passes 'true' if filter was executed automatically upon page load, else 'false'
	 */
	filtered_on_page_load?: boolean
	/**
	 * Product or content filters that the customer is using

	 */
	filters?: FiltersItem1[]
	/**
	 * Name of the targeted Algolia index. Maps to index in the Insights event payload
	 */
	index?: string
	/**
	 * Product or content list being viewed (analogous to Destination id)
	 */
	list_id?: string | null
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id?: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem2[]
	/**
	 * Product sorting that the customer is using
	 */
	sorts?: SortsItem[]
}
export interface CategoriesItem7 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category: string
}
export interface OffersItem5 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface ProductsItem3 {
	/**
	 * Brand associated with the product or content
	 */
	brand: string
	/**
	 * Categories associated with the product or page
	 */
	categories: CategoriesItem7[]
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product being viewed
	 */
	name: string
	/**
	 * The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
	 */
	objectID?: string
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem5[]
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id: string
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant?: string
}
export interface ProductListViewed {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category?: string | null
	/**
	 * Name of the targeted Algolia index. Maps to index in the Insights event payload
	 */
	index?: string
	/**
	 * Product or content list being viewed (analogous to Destination id)
	 */
	list_id?: string | null
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id?: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name?: string
	/**
	 * Human readable name for the previous page that the user was on
	 */
	previous_page_name?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem3[]
}
export interface CategoriesItem8 {
	/**
	 * Product or content category being viewed (analogous to Destination name)
	 */
	category: string
}
export interface OffersItem6 {
	/**
	 * The database id of an offer
	 */
	offer_id?: string
	/**
	 * The human readable name of an offer
	 */
	offer_name?: string
}
export interface ProductViewed {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Brand associated with the product or content
	 */
	brand: string
	/**
	 * Categories associated with the product or page
	 */
	categories: CategoriesItem8[]
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name: string
	/**
	 * Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
	 */
	nonInteraction?: number
	/**
	 * Offers associated with a product
	 */
	offers?: OffersItem6[]
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Price ($) of the product being viewed
	 */
	price: number
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id: string
	/**
	 * The total length of the trip (in days)
	 */
	trip_length: number
}
export interface FiltersItem2 {
	/**
	 * id of the filter type that the customer is using
	 */
	type?: string
	/**
	 * id of the selection that the customer chose
	 */
	value?: string
}
export interface ProductsSearched {
	/**
	 * Product or content filters that the customer is using

	 */
	filters?: FiltersItem2[]
	/**
	 * The database id of a module
	 */
	module_type_id: string
	/**
	 * The human readable type / classification of a module
	 */
	module_type_name: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The database id of a template type / classification of a page
	 */
	page_type_id: string
	/**
	 * The human readable template type / classification of a page
	 */
	page_type_name: string
	/**
	 * Query the user searched with

	 */
	query?: string
}
export interface CabinsItem4 {
	/**
	 * The human readable description of a cabin category
	 */
	cabin_category_description?: string
	/**
	 * The database id of a cabin category
	 */
	cabin_category_id?: string
	/**
	 * The number of adult occupants of a cabin
	 */
	passenger_adult_count?: number
	/**
	 * The number of child occupants of a cabin
	 */
	passenger_child_count?: number
	/**
	 * Per person price ($) of the cabin
	 */
	price?: number
}
export interface ProductsItem4 {
	/**
	 * The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
	 */
	objectID?: string
}
export interface ReservationRequestSubmitted {
	/**
	 * Brand associated with the product or content
	 */
	brand: string
	/**
	 * The number of cabins selected by the user
	 */
	cabin_quantity: number
	/**
	 * Cabins available on a ship
	 */
	cabins?: CabinsItem4[]
	checkout_id?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency: string
	/**
	 * Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
	 */
	days_until_trip?: number
	/**
	 * Departing date of a trip of a particular trip
	 */
	departure_date: string
	/**
	 * Total amount of deposit required to secure reservation
	 */
	deposit_amount?: number
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the targeted Algolia index. Maps to index in the Insights event payload
	 */
	index?: string
	/**
	 * Locale of website, e.g. USA, Australia, or World
	 */
	locale?: string
	/**
	 * Name of the product being viewedName of the product being viewed
	 */
	name: string
	/**
	 * Order/transaction ID
	 */
	order_id?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * Total number of passengers associated with the booking
	 */
	passenger_total_count?: number
	/**
	 * Payment method chosen
	 */
	payment_method: string | null
	/**
	 * Port of departure
	 */
	port_of_departure?: string
	/**
	 * Database id of the product being viewed (analogous to an itinerary id)
	 */
	product_id: string
	/**
	 * Products displayed in the product list (in order to pass objectID to Algolia)
	 */
	products?: ProductsItem4[]
	/**
	 * Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload
	 */
	queryID?: string
	/**
	 * Total value of the order
	 */
	revenue: number
	/**
	 * The database id of a ship
	 */
	ship_id?: string
	/**
	 * The human readable name of a ship
	 */
	ship_name?: string
	/**
	 * Database ID for the web transaction (e.g. form submission)
	 */
	transaction_id?: number
	/**
	 * The total length of the trip (in days)
	 */
	trip_length: number
	/**
	 * Variant of the product (analogous to a trip id)
	 */
	variant: string
}
export interface SawNudge {
	/**
	 * Whether qualaroo event is noninteractive
	 */
	noninteractive?: boolean
	/**
	 * Qualaroo nudge ID
	 */
	nudge_id?: string
	/**
	 * Qualaroo nudge name
	 */
	nudge_name?: string
}
export interface SignedIn {
	/**
	 * Whether user is an affinity & charter account holder
	 */
	affinity_charter?: boolean
	/**
	 * Whether a user is a myLEX account holder
	 */
	my_lex?: boolean
	/**
	 * Whether a user is a travel agent account holder
	 */
	travel_agent?: boolean
}
export interface SignedOut {
	/**
	 * Whether user is an affinity & charter account holder
	 */
	affinity_charter?: boolean
	/**
	 * Whether a user is a myLEX account holder
	 */
	my_lex?: boolean
	/**
	 * Whether a user is a travel agent account holder
	 */
	travel_agent?: boolean
}
export interface SignedUp {
	/**
	 * Whether user is an affinity & charter account holder
	 */
	affinity_charter?: boolean
	/**
	 * Whether a user is a myLEX account holder
	 */
	my_lex?: boolean
	/**
	 * Whether a user is a travel agent account holder
	 */
	travel_agent?: boolean
}
export interface SubNavigationClicked {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The text value of a button or rich text link
	 */
	text: string
	/**
	 * Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
	 */
	url: string
}
export interface VideoContentPlaying {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * URL source of the asset
	 */
	asset_source?: string
	/**
	 * True if playback is currently in full screen mode and false otherwise
	 */
	full_screen?: boolean
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
	 */
	position?: number
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound?: number
	/**
	 * The title of the video content
	 */
	title?: string
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * The name of the video player (for example youtube, vimeo)
	 */
	video_player?: string
}
export interface VideoPlaybackCompleted {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * URL source of the asset
	 */
	asset_source?: string
	/**
	 * True if playback is currently in full screen mode and false otherwise
	 */
	full_screen?: boolean
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
	 */
	position?: number
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound?: number
	/**
	 * The title of the video content
	 */
	title?: string
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * The name of the video player (for example youtube, vimeo)
	 */
	video_player?: string
}
export interface VideoPlaybackStarted {
	/**
	 * Framework of the application
	 */
	app_framework?: string
	/**
	 * URL source of the asset
	 */
	asset_source?: string
	/**
	 * True if playback is currently in full screen mode and false otherwise
	 */
	full_screen?: boolean
	/**
	 * Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
	 */
	page_name: string
	/**
	 * The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
	 */
	position?: number
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound?: number
	/**
	 * The title of the video content
	 */
	title?: string
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
	 */
	total_length?: number
	/**
	 * The name of the video player (for example youtube, vimeo)
	 */
	video_player?: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AnsweredNudge
 * @property {string} [answer] - User's answer in response to Qualaroo question
 * @property {string} [nudge_id] - Qualaroo nudge ID
 * @property {string} [nudge_name] - Qualaroo nudge name
 * @property {string} [question] - Qualaroo question
 */
/**
 * @typedef ButtonClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} text - The text value of a button or rich text link
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page.
 */
/**
 * @typedef ChatOpened
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef ChatRequested
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef CheckoutExited
 * @property {string} [checkout_id] -
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [order_id] - Order/transaction ID
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [step] - Number representing a step in the checkout process or a form flow
 * @property {string} [step_name] - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} [step_total] - The total number of steps in a multi-step checkout or form flow
 * @property {string} [text] - The text value of a button or rich text link
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page
 */
/**
 * @typedef CabinsItem
 * @property {string} [cabin_category_description] - The human readable description of a cabin category
 * @property {string} [cabin_category_id] - The database id of a cabin category
 * @property {number} [passenger_adult_count] - The number of adult occupants of a cabin
 * @property {number} [passenger_child_count] - The number of child occupants of a cabin
 * @property {number} [price] - Per person price ($) of the cabin
 */
/**
 * @typedef CategoriesItem
 * @property {string} [category] - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [booking_number] - ID associated with the booking
 * @property {string} [brand] - Brand associated with the product or content
 * @property {number} [cabin_quantity] - The number of cabins selected by the user
 * @property {CabinsItem[]} [cabins] - Cabins available on a ship
 * @property {CategoriesItem[]} [categories] - Categories associated with the product or page
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} [departure_date] - Departing date of a trip of a particular trip
 * @property {string} [image_url] - Image url of the product
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [name] - Name of the product being viewedName of the product being viewed
 * @property {OffersItem[]} [offers] - Offers associated with a product
 * @property {string} [order_id] - Order/transaction ID
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [passenger_total_count] - Total number of passengers associated with the booking
 * @property {string} [port_of_departure] - Port of departure
 * @property {string} [previous_page_name] - Human readable name for the previous page that the user was on
 * @property {number} [price] - Price ($) of the product being viewed
 * @property {string} [product_id] - Database id of the product being viewed (analogous to an itinerary id)
 * @property {number} [revenue] - Total value of the order
 * @property {string} [role] - The user's role
 * @property {number} [step] - Number representing a step in the checkout process or a form flow
 * @property {string} step_name - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} [step_total] - The total number of steps in a multi-step checkout or form flow
 * @property {number} [trip_length] - The total length of the trip (in days)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef CabinsItem1
 * @property {string} [cabin_category_description] - The human readable description of a cabin category
 * @property {string} [cabin_category_id] - The database id of a cabin category
 * @property {number} [passenger_adult_count] - The number of adult occupants of a cabin
 * @property {number} [passenger_child_count] - The number of child occupants of a cabin
 * @property {number} [price] - Per person price ($) of the cabin
 */
/**
 * @typedef CategoriesItem1
 * @property {string} [category] - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem1
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [booking_number] - ID associated with the booking
 * @property {string} [brand] - Brand associated with the product or content
 * @property {number} [cabin_quantity] - The number of cabins selected by the user
 * @property {CabinsItem1[]} [cabins] - Cabins available on a ship
 * @property {CategoriesItem1[]} [categories] - Categories associated with the product or page
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} [departure_date] - Departing date of a trip of a particular trip
 * @property {number} [deposit_amount] - Total amount of deposit required to secure reservation
 * @property {string} [image_url] - Image url of the product
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [name] - Name of the product being viewedName of the product being viewed
 * @property {OffersItem1[]} [offers] - Offers associated with a product
 * @property {string} [order_id] - Order/transaction ID
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [passenger_total_count] - Total number of passengers associated with the booking
 * @property {string} [port_of_departure] - Port of departure
 * @property {number} [price] - Price ($) of the product being viewed
 * @property {string} [product_id] - Database id of the product being viewed (analogous to an itinerary id)
 * @property {number} [revenue] - Total value of the order
 * @property {string} [role] - The user's role
 * @property {number} step - Number representing a step in the checkout process or a form flow
 * @property {string} step_name - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} [step_total] - The total number of steps in a multi-step checkout or form flow
 * @property {number} [trip_length] - The total length of the trip (in days)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef CabinsItem2
 * @property {string} [cabin_category_description] - The human readable description of a cabin category
 * @property {string} [cabin_category_id] - The database id of a cabin category
 * @property {number} [passenger_adult_count] - The number of adult occupants of a cabin
 * @property {number} [passenger_child_count] - The number of child occupants of a cabin
 * @property {number} [price] - Per person price ($) of the cabin
 */
/**
 * @typedef CategoriesItem2
 * @property {string} [category] - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem2
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [booking_number] - ID associated with the booking
 * @property {string} [brand] - Brand associated with the product or content
 * @property {number} [cabin_quantity] - The number of cabins selected by the user
 * @property {CabinsItem2[]} [cabins] - Cabins available on a ship
 * @property {CategoriesItem2[]} [categories] - Categories associated with the product or page
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} [departure_date] - Departing date of a trip of a particular trip
 * @property {number} [deposit_amount] - Total amount of deposit required to secure reservation
 * @property {string} [image_url] - Image url of the product
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [name] - Name of the product being viewedName of the product being viewed
 * @property {OffersItem2[]} [offers] - Offers associated with a product
 * @property {string} [order_id] - Order/transaction ID
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [passenger_total_count] - Total number of passengers associated with the booking
 * @property {string} [port_of_departure] - Port of departure
 * @property {number} [price] - Price ($) of the product being viewed
 * @property {string} [product_id] - Database id of the product being viewed (analogous to an itinerary id)
 * @property {number} [revenue] - Total value of the order
 * @property {string} [role] - The user's role
 * @property {number} step - Number representing a step in the checkout process or a form flow
 * @property {string} step_name - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} [step_total] - The total number of steps in a multi-step checkout or form flow
 * @property {number} [trip_length] - The total length of the trip (in days)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef ErrorReceived
 * @property {string} [brand] - Brand associated with the product or content
 * @property {number} [captcha_score] - User's captcha score
 * @property {string} [error_context] - Backend context around what prompted the error message
 * @property {string} [error_message] - Error message that the front-end user sees
 * @property {string} [error_source] - Source system of the error message
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef ExperimentViewed
 * @property {string | null} [audienceId] - The experiment's audience id
 * @property {string | null} [audienceName] - The experiment's audience name
 * @property {string} [campaignId] - The experiment's campaign id
 * @property {string} [campaignName] - The experiment's campaign name
 * @property {string} [experimentId] - The experiment's id
 * @property {string} [experimentName] - The experiment's human readable name
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} [variationId] - The variation id
 * @property {string} [variationName] - The variation human readable name
 */
/**
 * @typedef FiltersItem
 * @property {string} type - id of the filter type that the customer is using
 * @property {string} value - id of the selection that the customer chose
 */
/**
 * @typedef FiltersApplied
 * @property {FiltersItem[]} filters - Product or content filters that the customer is using

 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) 
 */
/**
 * @typedef FormStepCompleted
 * @property {string} form_id - The database id of a form
 * @property {string} form_name - The human readable name of a form
 * @property {string} form_type - The type of form (newsletter, brochure request, DER)
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} step - Number representing a step in the checkout process or a form flow
 * @property {string} step_name - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} step_total - The total number of steps in a multi-step checkout or form flow
 */
/**
 * @typedef FormStepViewed
 * @property {string} form_id - The database id of a form
 * @property {string} form_name - The human readable name of a form
 * @property {string} form_type - The type of form (newsletter, brochure request, DER)
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} step - Number representing a step in the checkout process or a form flow
 * @property {string} step_name - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} step_total - The total number of steps in a multi-step checkout or form flow
 */
/**
 * @typedef FormSubmissionAttempted
 * @property {string} [form_id] - The database id of a form
 * @property {string} [form_name] - The human readable name of a form
 * @property {string} [form_type] - The type of form (newsletter, brochure request, DER)
 * @property {string} [label] - Descriptive label of an event
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef FormSubmitted
 * @property {string} [age_group] - Age group categorization as selected by a user (ex. 18 & under, 19 - 45, etc.)
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [brochure_id] - The database id of a brochure
 * @property {string} [brochure_name] - The human readable name of a brochure
 * @property {number} [captcha_score] - User's captcha score
 * @property {string} [form_id] - The database id of a form
 * @property {string} form_name - The human readable name of a form
 * @property {string} form_type - The type of form (newsletter, brochure request, DER)
 * @property {string} [label] - Descriptive label of an event
 * @property {string} [page_name] - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [referral_source] - How a user was referred to the site as selected by a user (ex. Received a Brochure, Received an Email, etc.)
 * @property {string[]} [topic_interests] - Topics of interest as selected by a user
 * @property {number} [transaction_id] - Database ID for the web transaction (e.g. form submission)
 * @property {string} [travel_preference] - Travel preference of the guest, e.g. travel solo, with guests, etc.
 */
/**
 * @typedef FormViewed
 * @property {string} form_id - The database id of a form
 * @property {string} form_name - The human readable name of a form
 * @property {string} form_type - The type of form (newsletter, brochure request, DER)
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef GlobalNavigationClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [navigation_category] - Navigation category, i.e. main navigational category that user clicked through such as 'Destinations', 'Ships', etc
 * @property {string} navigation_level - Whether user clicked on element in the 'header' or 'footer'
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} text - The text value of a button or rich text link
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
 */
/**
 * @typedef LinkClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [link_style] - Style of the link, e.g. rich text, button, etc
 * @property {string} [module_type_id] - The database id of a module
 * @property {string} [module_type_name] - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} text - The text value of a button or rich text link
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page.
 */
/**
 * @typedef ModuleClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [component_id] - The database ID of the component
 * @property {string} [component_name] - The human readable name of the component
 * @property {string | null} [module_type_id] - The database id of a module
 * @property {string} module_type_name - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [page_section] - Section of the page, e.g. 'highlights', 'day by day', etc.
 * @property {number} [position] - Position in the list (ex. 3)
 * @property {string} [text] - The text value of a button or rich text link
 * @property {string} [url] - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page
 */
/**
 * @typedef ModuleClosed
 * @property {string} [module_type_id] - The database id of a module
 * @property {string} module_type_name - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef ModuleInteraction
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [brand] - Brand associated with the product or content
 * @property {string} [component_name] - The human readable name of the component
 * @property {number} [count_components] - The count of components within a given module
 * @property {string} [interaction_type] - The type of module interaction
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string | null} [module_type_id] - The database id of a module
 * @property {string} [module_type_name] - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [page_section] - Section of the page, e.g. 'highlights', 'day by day', etc.
 * @property {string} [page_type_id] - The database id of a template type / classification of a page
 * @property {string} [page_type_name] - The human readable template type / classification of a page
 * @property {number} [position] - Position in the product list (ex. 3)
 * @property {string} [text] - The text value of a button or rich text link
 */
/**
 * @typedef ModuleOpened
 * @property {string} [module_type_id] - The database id of a module
 * @property {string} module_type_name - The human readable type / classification of a module
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef ModuleViewed
 * @property {string} [app_framework] - Framework of the application
 * @property {string | null} [module_type_id] - The database id of a module
 * @property {string} module_type_name - The human readable type / classification of a module
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 */
/**
 * @typedef CabinsItem3
 * @property {string} [cabin_category_description] - The human readable description of a cabin category
 * @property {string} [cabin_category_id] - The database id of a cabin category
 * @property {number} [passenger_adult_count] - The number of adult occupants of a cabin
 * @property {number} [passenger_child_count] - The number of child occupants of a cabin
 * @property {number} [price] - Per person price ($) of the cabin
 */
/**
 * @typedef CategoriesItem3
 * @property {string} [category] - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OrderCompleted
 * @property {string} [booking_number] - ID associated with the booking
 * @property {string} [brand] - Brand associated with the product or content
 * @property {number} [cabin_quantity] - The number of cabins selected by the user
 * @property {CabinsItem3[]} [cabins] - Cabins available on a ship
 * @property {CategoriesItem3[]} [categories] - Categories associated with the product or page
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} [departure_date] - Departing date of a trip of a particular trip
 * @property {number} [deposit_amount] - Total amount of deposit required to secure reservation
 * @property {string} [image_url] - Image url of the product
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [name] - Name of the product being viewedName of the product being viewed
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [passenger_total_count] - Total number of passengers associated with the booking
 * @property {string} [payment_method] - Payment method chosen
 * @property {string} [product_id] - Database id of the product being viewed (analogous to an itinerary id)
 * @property {number} [revenue] - Total value of the order
 * @property {string} [role] - The user's role
 * @property {boolean} [server_side] - Whether event was triggered via a server-side source as opposed to JS
 * @property {string} [ship_id] - The database id of a ship
 * @property {string} [ship_name] - The human readable name of a ship
 * @property {number} [trip_length] - The total length of the trip (in days)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef PagePerformanceCaptured
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [connection_type] - Type of connection the user has, e.g. 4g, wifi, etc
 * @property {number} [count_cls] - Count of cumulative layout shifts
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {number} [page_cls] - Cumulative Layout Shift (CLS) metric of the page
 * @property {number} [page_fcp] - First Contentful Paint (FCP) metric of the page
 * @property {number} [page_fid] - First Input Delay (FID) metric of the page
 * @property {number} [page_lcp] - Largest Contentful Paint (LCP) of the page
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [page_ttfb] - Time to First Byte (TTFB) of the page
 * @property {string} page_type_id - The database id of a template type / classification of a page
 * @property {string} page_type_name - The human readable template type / classification of a page
 */
/**
 * @typedef ProductsItem
 * @property {string} [name] - Name of the product being viewed
 */
/**
 * @typedef PageScrolled
 * @property {string} [app_framework] - Framework of the application
 * @property {number} depth - The quarterly interval / heartbeat for scroll depth of a page or screen
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {ProductsItem[]} [products] - Products displayed in the product list
 */
/**
 * @typedef BrochuresItem
 * @property {string} [brochure_id] - The database id of a brochure
 * @property {string} [brochure_name] - The human readable name of a brochure
 */
/**
 * @typedef CategoriesItem4
 * @property {string} [category] - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef ProductsItem1
 * @property {string} [brand] - Brand associated with the product or content
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product being viewed
 * @property {string} [product_id] - Database id of the product being viewed (analogous to an itinerary id)
 */
/**
 * @typedef ShipsItem
 * @property {string} [ship_id] - The database id of a ship
 * @property {string} [ship_name] - The human readable name of a ship
 */
/**
 * @typedef TeamMembersItem
 * @property {string} [team_member_id] - The database id of a team member
 * @property {string} [team_member_name] - The name of a team member
 * @property {string} [team_member_role] - The human readable role name of a team member
 */
/**
 * @typedef PageViewed
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [brand] - Brand associated with the product or content
 * @property {BrochuresItem[]} [brochures] - Brochures associated with the product or content
 * @property {CategoriesItem4[]} [categories] - Categories associated with the product or page
 * @property {string} [deep_link] - Value of anchor tag that deep links user to specific content within a page, e.g. within page call for https://www.expeditions.com/destinations/belize-and-guatemala#daily-expedition-reports we would pass 'daily-expedition-reports' as the deep_link value
 * @property {boolean} is_landing - Whether page viewed is the user's landing page (i.e. where they entered the site either directly or from another domain)
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} name - Human readable name for the page
 * @property {string} [page_type_id] - The database id of a template type / classification of a page
 * @property {string} [page_type_name] - The human readable template type / classification of a page
 * @property {string} path - Path portion of the URL of the page. Equivalent to canonical path which defaults to location.pathname from the DOM API
 * @property {ProductsItem1[]} [products] - Products displayed in the product list
 * @property {string} referrer - Full URL of the previous page. Equivalent to document.referrer from the DOM API
 * @property {string} search - Query string portion of the URL of the page. Equivalent to location.search from the DOM API
 * @property {ShipsItem[]} [ships] - Ships associated with the product or content
 * @property {string[]} [tags_applied] - Array of tag categories applied
 * @property {any[]} [tags_audience] - Array of audience tags applied to content
 * @property {any[]} [tags_content_pillar] - Array of trip type tags applied to content
 * @property {any[]} [tags_content_type] - Array of content type tags applied to content
 * @property {any[]} [tags_destination] - Array of destination tags applied to content
 * @property {any[]} [tags_funnel] - Array of funnel tags applied to content
 * @property {any[]} [tags_geographies] - Array of geography tags applied to content
 * @property {any[]} [tags_interest_activity] - Array of trip type tags applied to content
 * @property {any[]} [tags_itinerary] - Array of itinerary tags applied to content
 * @property {any[]} [tags_promotions] - Array of promotions tags applied to content
 * @property {any[]} [tags_series_campaign] - array of series / campaign tags applied to content
 * @property {any[]} [tags_ship] - Array of ship tags applied to content
 * @property {any[]} [tags_source] - Array of source tags applied to content
 * @property {any[]} [tags_trip_type] - Array of trip type tags applied to content
 * @property {TeamMembersItem[]} [team_members] - Team members associated with the product or page
 * @property {string} title - Title of the page. Equivalent to document.title from the DOM API
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [brand] - Brand associated with the product or content
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [name] - Name of the product being viewedName of the product being viewed
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [role] - The user's role
 * @property {number} [step] - Number representing a step in the checkout process or a form flow
 * @property {string} [step_name] - Human readable name / title of a step in a multi-stage checkout or form flow
 * @property {number} [step_total] - The total number of steps in a multi-step checkout or form flow
 */
/**
 * @typedef PhoneNumberClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [module_type_id] - The database id of a module
 * @property {string} [module_type_name] - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} phone - Phone number
 */
/**
 * @typedef CategoriesItem5
 * @property {string} category - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem3
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef ProductClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} brand - Brand associated with the product or content
 * @property {CategoriesItem5[]} [categories] - Categories associated with the product or page
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} [departure_date] - Departing date of a trip of a particular trip
 * @property {string} image_url - Image url of the product
 * @property {string} [index] - Name of the targeted Algolia index. Maps to index in the Insights event payload
 * @property {string} [list_id] - Product or content list being viewed (analogous to Destination id)
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} [module_type_id] - The database id of a module
 * @property {string} [module_type_name] - The human readable type / classification of a module
 * @property {string} name - Name of the product being viewedName of the product being viewed
 * @property {string} [objectID] - The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
 * @property {OffersItem3[]} [offers] - Offers associated with a product
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [page_type_id] - The database id of a template type / classification of a page
 * @property {string} [page_type_name] - The human readable template type / classification of a page
 * @property {string} [port_of_departure] - Port of departure
 * @property {number} [position] - Position in the product list (ex. 3)
 * @property {number} [price] - Price ($) of the product being viewed
 * @property {string} product_id - Database id of the product being viewed (analogous to an itinerary id)
 * @property {string} [queryID] - Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload
 * @property {number} [trip_length] - The total length of the trip (in days)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef FiltersItem1
 * @property {string} type - id of the filter type that the customer is using
 * @property {string} value - id of the selection that the customer chose
 */
/**
 * @typedef CategoriesItem6
 * @property {string} category - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem4
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef ProductsItem2
 * @property {string} brand - Brand associated with the product or content
 * @property {CategoriesItem6[]} categories - Categories associated with the product or page
 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product being viewed
 * @property {OffersItem4[]} [offers] - Offers associated with a product
 * @property {string} product_id - Database id of the product being viewed (analogous to an itinerary id)
 */
/**
 * @typedef SortsItem
 * @property {string} [type] - id of the sort type that the customer is using
 * @property {string} [value] - id of the selection type the customer is using (ascending, descending)
 */
/**
 * @typedef ProductListFiltered
 * @property {string | null} [category] - Product or content category being viewed (analogous to Destination name)
 * @property {boolean} [filtered_on_page_load] - Passes 'true' if filter was executed automatically upon page load, else 'false' 
 * @property {FiltersItem1[]} [filters] - Product or content filters that the customer is using

 * @property {string} [index] - Name of the targeted Algolia index. Maps to index in the Insights event payload
 * @property {string | null} [list_id] - Product or content list being viewed (analogous to Destination id)
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {number} nonInteraction - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) 
 * @property {string} [page_type_id] - The database id of a template type / classification of a page
 * @property {string} [page_type_name] - The human readable template type / classification of a page
 * @property {ProductsItem2[]} products - Products displayed in the product list
 * @property {SortsItem[]} [sorts] - Product sorting that the customer is using
 */
/**
 * @typedef CategoriesItem7
 * @property {string} category - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem5
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef ProductsItem3
 * @property {string} brand - Brand associated with the product or content
 * @property {CategoriesItem7[]} categories - Categories associated with the product or page
 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product being viewed
 * @property {string} [objectID] - The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
 * @property {OffersItem5[]} [offers] - Offers associated with a product
 * @property {string} product_id - Database id of the product being viewed (analogous to an itinerary id)
 * @property {string} [variant] - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef ProductListViewed
 * @property {string} [app_framework] - Framework of the application
 * @property {string | null} [category] - Product or content category being viewed (analogous to Destination name)
 * @property {string} [index] - Name of the targeted Algolia index. Maps to index in the Insights event payload
 * @property {string | null} [list_id] - Product or content list being viewed (analogous to Destination id)
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} [page_type_id] - The database id of a template type / classification of a page
 * @property {string} [page_type_name] - The human readable template type / classification of a page
 * @property {string} [previous_page_name] - Human readable name for the previous page that the user was on
 * @property {ProductsItem3[]} products - Products displayed in the product list
 */
/**
 * @typedef CategoriesItem8
 * @property {string} category - Product or content category being viewed (analogous to Destination name)
 */
/**
 * @typedef OffersItem6
 * @property {string} [offer_id] - The database id of an offer
 * @property {string} [offer_name] - The human readable name of an offer
 */
/**
 * @typedef ProductViewed
 * @property {string} [app_framework] - Framework of the application
 * @property {string} brand - Brand associated with the product or content
 * @property {CategoriesItem8[]} categories - Categories associated with the product or page
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {string} image_url - Image url of the product
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} name - Name of the product being viewedName of the product being viewed
 * @property {number} [nonInteraction] - Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction
 * @property {OffersItem6[]} [offers] - Offers associated with a product
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} price - Price ($) of the product being viewed
 * @property {string} product_id - Database id of the product being viewed (analogous to an itinerary id)
 * @property {number} trip_length - The total length of the trip (in days)
 */
/**
 * @typedef FiltersItem2
 * @property {string} [type] - id of the filter type that the customer is using
 * @property {string} [value] - id of the selection that the customer chose
 */
/**
 * @typedef ProductsSearched
 * @property {FiltersItem2[]} [filters] - Product or content filters that the customer is using

 * @property {string} module_type_id - The database id of a module
 * @property {string} module_type_name - The human readable type / classification of a module
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) 
 * @property {string} page_type_id - The database id of a template type / classification of a page
 * @property {string} page_type_name - The human readable template type / classification of a page
 * @property {string} [query] - Query the user searched with

 */
/**
 * @typedef CabinsItem4
 * @property {string} [cabin_category_description] - The human readable description of a cabin category
 * @property {string} [cabin_category_id] - The database id of a cabin category
 * @property {number} [passenger_adult_count] - The number of adult occupants of a cabin
 * @property {number} [passenger_child_count] - The number of child occupants of a cabin
 * @property {number} [price] - Per person price ($) of the cabin
 */
/**
 * @typedef ProductsItem4
 * @property {string} [objectID] - The objectID of the record. Maps to objectIDs in the Algolia Insights event payload
 */
/**
 * @typedef ReservationRequestSubmitted
 * @property {string} brand - Brand associated with the product or content
 * @property {number} cabin_quantity - The number of cabins selected by the user
 * @property {CabinsItem4[]} [cabins] - Cabins available on a ship
 * @property {string} [checkout_id] -
 * @property {string} currency - Currency code associated with the transaction
 * @property {number} [days_until_trip] - Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc.
 * @property {string} departure_date - Departing date of a trip of a particular trip
 * @property {number} [deposit_amount] - Total amount of deposit required to secure reservation
 * @property {string} image_url - Image url of the product
 * @property {string} [index] - Name of the targeted Algolia index. Maps to index in the Insights event payload
 * @property {string} [locale] - Locale of website, e.g. USA, Australia, or World
 * @property {string} name - Name of the product being viewedName of the product being viewed
 * @property {string} [order_id] - Order/transaction ID
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [passenger_total_count] - Total number of passengers associated with the booking
 * @property {string | null} payment_method - Payment method chosen
 * @property {string} [port_of_departure] - Port of departure
 * @property {string} product_id - Database id of the product being viewed (analogous to an itinerary id)
 * @property {ProductsItem4[]} [products] - Products displayed in the product list (in order to pass objectID to Algolia)
 * @property {string} [queryID] - Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload
 * @property {number} revenue - Total value of the order
 * @property {string} [ship_id] - The database id of a ship
 * @property {string} [ship_name] - The human readable name of a ship
 * @property {number} [transaction_id] - Database ID for the web transaction (e.g. form submission)
 * @property {number} trip_length - The total length of the trip (in days)
 * @property {string} variant - Variant of the product (analogous to a trip id)
 */
/**
 * @typedef SawNudge
 * @property {boolean} [noninteractive] - Whether qualaroo event is noninteractive
 * @property {string} [nudge_id] - Qualaroo nudge ID
 * @property {string} [nudge_name] - Qualaroo nudge name
 */
/**
 * @typedef SignedIn
 * @property {boolean} [affinity_charter] - Whether user is an affinity & charter account holder
 * @property {boolean} [my_lex] - Whether a user is a myLEX account holder
 * @property {boolean} [travel_agent] - Whether a user is a travel agent account holder
 */
/**
 * @typedef SignedOut
 * @property {boolean} [affinity_charter] - Whether user is an affinity & charter account holder
 * @property {boolean} [my_lex] - Whether a user is a myLEX account holder
 * @property {boolean} [travel_agent] - Whether a user is a travel agent account holder
 */
/**
 * @typedef SignedUp
 * @property {boolean} [affinity_charter] - Whether user is an affinity & charter account holder
 * @property {boolean} [my_lex] - Whether a user is a myLEX account holder
 * @property {boolean} [travel_agent] - Whether a user is a travel agent account holder
 */
/**
 * @typedef SubNavigationClicked
 * @property {string} [app_framework] - Framework of the application
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {string} text - The text value of a button or rich text link
 * @property {string} url - Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API
 */
/**
 * @typedef VideoContentPlaying
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [asset_source] - URL source of the asset
 * @property {boolean} [full_screen] - True if playback is currently in full screen mode and false otherwise
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [position] - The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
 * @property {number} [sound] - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {string} [title] - The title of the video content
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - The name of the video player (for example youtube, vimeo)
 */
/**
 * @typedef VideoPlaybackCompleted
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [asset_source] - URL source of the asset
 * @property {boolean} [full_screen] - True if playback is currently in full screen mode and false otherwise
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [position] - The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
 * @property {number} [sound] - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {string} [title] - The title of the video content
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - The name of the video player (for example youtube, vimeo)
 */
/**
 * @typedef VideoPlaybackStarted
 * @property {string} [app_framework] - Framework of the application
 * @property {string} [asset_source] - URL source of the asset
 * @property {boolean} [full_screen] - True if playback is currently in full screen mode and false otherwise
 * @property {string} page_name - Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls)
 * @property {number} [position] - The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...
 * @property {number} [sound] - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {string} [title] - The title of the video content
 * @property {number} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null
 * @property {string} [video_player] - The name of the video player (for example youtube, vimeo)
 */

/**
 * User answers a Qualaroo nudge
 *
 * @param {AnsweredNudge} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function answeredNudge(
	props?: AnsweredNudge,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User answers a Qualaroo nudge',
		properties: {
			context: {},
			properties: {
				properties: {
					answer: {
						description: "User's answer in response to Qualaroo question ",
						type: 'string',
					},
					nudge_id: {
						description: 'Qualaroo nudge ID',
						type: 'string',
					},
					nudge_name: {
						description: 'Qualaroo nudge name',
						type: 'string',
					},
					question: {
						description: 'Qualaroo question',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Answered nudge',
		type: 'object',
	}
	const message = {
		event: 'Answered nudge',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Answered nudge',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any button on the website
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
	props: ButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on any button on the website',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					text: {
						description: 'The text value of a button or rich text link',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page.',
						type: 'string',
					},
				},
				required: ['url', 'text', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Button Clicked',
		type: 'object',
	}
	const message = {
		event: 'Button Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires when a user opens the chat module
 *
 * @param {ChatOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chatOpened(
	props?: ChatOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fires when a user opens the chat module',
		properties: {
			context: {},
			properties: {
				properties: {
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Chat Opened',
		type: 'object',
	}
	const message = {
		event: 'Chat Opened',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Chat Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires when a user requests a chat with an agent
 *
 * @param {ChatRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chatRequested(
	props?: ChatRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fires when a user requests a chat with an agent',
		properties: {
			context: {},
			properties: {
				properties: {
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Chat Requested',
		type: 'object',
	}
	const message = {
		event: 'Chat Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Chat Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User exits the checkout flow, e.g. by clicking Leave Booking, or opening some other page on the site that removes them from the flow
 *
 * @param {CheckoutExited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutExited(
	props?: CheckoutExited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User exits the checkout flow, e.g. by clicking Leave Booking, or opening some other page on the site that removes them from the flow',
		properties: {
			context: {},
			properties: {
				properties: {
					checkout_id: {
						description: '',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					order_id: {
						description: 'Order/transaction ID',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
					text: {
						description: 'The text value of a button or rich text link',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page',
						type: 'string',
					},
				},
				required: ['url', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Checkout Exited',
		type: 'object',
	}
	const message = {
		event: 'Checkout Exited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Exited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User first enters the reservation request / booking flow
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User first enters the reservation request / booking flow',
		properties: {
			context: {},
			properties: {
				properties: {
					booking_number: {
						description: 'ID associated with the booking',
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					cabin_quantity: {
						description: 'The number of cabins selected by the user',
						type: 'integer',
					},
					cabins: {
						description: 'Cabins available on a ship',
						items: {
							description: '',
							properties: {
								cabin_category_description: {
									description:
										'The human readable description of a cabin category',
									type: 'string',
								},
								cabin_category_id: {
									description: 'The database id of a cabin category',
									type: 'string',
								},
								passenger_adult_count: {
									description: 'The number of adult occupants of a cabin',
									type: 'integer',
								},
								passenger_child_count: {
									description: 'The number of child occupants of a cabin',
									type: 'integer',
								},
								price: {
									description: 'Per person price ($) of the cabin',
									type: 'number',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					checkout_id: {
						description: 'Checkout transaction ID',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						type: 'string',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					offers: {
						description: 'Offers associated with a product',
						items: {
							description: '',
							properties: {
								offer_id: {
									description: 'The database id of an offer',
									type: 'string',
								},
								offer_name: {
									description: 'The human readable name of an offer',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					order_id: {
						description: 'Order/transaction ID',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					passenger_total_count: {
						description:
							'Total number of passengers associated with the booking',
						type: 'integer',
					},
					port_of_departure: {
						description: 'Port of departure',
						type: 'string',
					},
					previous_page_name: {
						description:
							'Human readable name for the previous page that the user was on',
						type: 'string',
					},
					price: {
						description: 'Price ($) of the product being viewed',
						type: 'number',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					revenue: {
						description: 'Total value of the order',
						type: 'number',
					},
					role: {
						description: "The user's role",
						enum: ['guest', 'travel agent'],
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						type: 'string',
					},
				},
				required: ['page_name', 'step_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Checkout Started',
		type: 'object',
	}
	const message = {
		event: 'Checkout Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a step in the reservation request / booking flow
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props: CheckoutStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User completed a step in the reservation request / booking flow',
		properties: {
			context: {},
			properties: {
				properties: {
					booking_number: {
						description: 'ID associated with the booking',
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					cabin_quantity: {
						description: 'The number of cabins selected by the user',
						type: 'integer',
					},
					cabins: {
						description: 'Cabins available on a ship',
						items: {
							description: '',
							properties: {
								cabin_category_description: {
									description:
										'The human readable description of a cabin category',
									type: 'string',
								},
								cabin_category_id: {
									description: 'The database id of a cabin category',
									type: 'string',
								},
								passenger_adult_count: {
									description: 'The number of adult occupants of a cabin',
									type: 'integer',
								},
								passenger_child_count: {
									description: 'The number of child occupants of a cabin',
									type: 'integer',
								},
								price: {
									description: 'Per person price ($) of the cabin',
									type: 'number',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					checkout_id: {
						description: 'Checkout transaction ID',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						type: 'string',
					},
					deposit_amount: {
						description:
							'Total amount of deposit required to secure reservation',
						type: 'number',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					offers: {
						description: 'Offers associated with a product',
						items: {
							description: '',
							properties: {
								offer_id: {
									description: 'The database id of an offer',
									type: 'string',
								},
								offer_name: {
									description: 'The human readable name of an offer',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					order_id: {
						description: 'Order/transaction ID',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					passenger_total_count: {
						description:
							'Total number of passengers associated with the booking',
						type: 'integer',
					},
					port_of_departure: {
						description: 'Port of departure',
						type: 'string',
					},
					price: {
						description: 'Price ($) of the product being viewed',
						type: 'number',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					revenue: {
						description: 'Total value of the order',
						type: 'number',
					},
					role: {
						description: "The user's role",
						enum: ['guest', 'travel agent'],
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						type: 'string',
					},
				},
				required: ['page_name', 'step', 'step_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Checkout Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Checkout Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a step in the reservation request / booking flow
 *
 * @param {CheckoutStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(
	props: CheckoutStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User viewed a step in the reservation request / booking flow',
		properties: {
			context: {},
			properties: {
				properties: {
					booking_number: {
						description: 'ID associated with the booking',
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					cabin_quantity: {
						description: 'The number of cabins selected by the user',
						type: 'integer',
					},
					cabins: {
						description: 'Cabins available on a ship',
						items: {
							description: '',
							properties: {
								cabin_category_description: {
									description:
										'The human readable description of a cabin category',
									type: 'string',
								},
								cabin_category_id: {
									description: 'The database id of a cabin category',
									type: 'string',
								},
								passenger_adult_count: {
									description: 'The number of adult occupants of a cabin',
									type: 'integer',
								},
								passenger_child_count: {
									description: 'The number of child occupants of a cabin',
									type: 'integer',
								},
								price: {
									description: 'Per person price ($) of the cabin',
									type: 'number',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					checkout_id: {
						description: 'Checkout transaction ID',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						type: 'string',
					},
					deposit_amount: {
						description:
							'Total amount of deposit required to secure reservation',
						type: 'number',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					offers: {
						description: 'Offers associated with a product',
						items: {
							description: '',
							properties: {
								offer_id: {
									description: 'The database id of an offer',
									type: 'string',
								},
								offer_name: {
									description: 'The human readable name of an offer',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					order_id: {
						description: 'Order/transaction ID',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					passenger_total_count: {
						description:
							'Total number of passengers associated with the booking',
						type: 'integer',
					},
					port_of_departure: {
						description: 'Port of departure',
						type: 'string',
					},
					price: {
						description: 'Price ($) of the product being viewed',
						type: 'number',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					revenue: {
						description: 'Total value of the order',
						type: 'number',
					},
					role: {
						description: "The user's role",
						enum: ['guest', 'travel agent'],
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						type: 'string',
					},
				},
				required: ['step_name', 'page_name', 'step'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Checkout Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Checkout Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clears selection from dropdown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownItemRemoved(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user clears selection from dropdown                                                                        ',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Dropdown Item Removed',
		type: 'object',
	}
	const message = {
		event: 'Dropdown Item Removed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Dropdown Item Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user selects from a dropdown list
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownItemSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user selects from a dropdown list                                                                        ',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Dropdown Item Selected',
		type: 'object',
	}
	const message = {
		event: 'Dropdown Item Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Dropdown Item Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user toggles a dropdown list
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dropdownMenuOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user toggles a dropdown list                                                                        ',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Dropdown Menu Opened',
		type: 'object',
	}
	const message = {
		event: 'Dropdown Menu Opened',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Dropdown Menu Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires when user encounters an error
 *
 * @param {ErrorReceived} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorReceived(
	props: ErrorReceived,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fires when user encounters an error',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					captcha_score: {
						description: "User's captcha score",
						type: 'number',
					},
					error_context: {
						description:
							'Backend context around what prompted the error message',
						type: 'string',
					},
					error_message: {
						description: 'Error message that the front-end user sees',
						type: 'string',
					},
					error_source: {
						description: 'Source system of the error message',
						enum: ['Episerver', 'ExpeditionsApi', 'General'],
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Error Received',
		type: 'object',
	}
	const message = {
		event: 'Error Received',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Error Received',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views an A/B test (passes automatically when an Optimizely experiment is detected)
 *
 * @param {ExperimentViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentViewed(
	props?: ExperimentViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User views an A/B test (passes automatically when an Optimizely experiment is detected)',
		properties: {
			context: {},
			properties: {
				properties: {
					audienceId: {
						description: "The experiment's audience id",
						type: ['string', 'null'],
					},
					audienceName: {
						description: "The experiment's audience name",
						type: ['string', 'null'],
					},
					campaignId: {
						description: "The experiment's campaign id",
						type: 'string',
					},
					campaignName: {
						description: "The experiment's campaign name",
						type: 'string',
					},
					experimentId: {
						description: "The experiment's id",
						type: 'string',
					},
					experimentName: {
						description: "The experiment's human readable name",
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					variationId: {
						description: 'The variation id',
						type: 'string',
					},
					variationName: {
						description: 'The variation human readable name',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Experiment Viewed',
		type: 'object',
	}
	const message = {
		event: 'Experiment Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Experiment Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user applies all selected filterable attributes
 *
 * @param {FiltersApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filtersApplied(
	props: FiltersApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user applies all selected filterable attributes                                                              ',
		properties: {
			context: {},
			properties: {
				properties: {
					filters: {
						description:
							'Product or content filters that the customer is using\n',
						items: {
							description: '',
							properties: {
								type: {
									description:
										'id of the filter type that the customer is using',
									type: 'string',
								},
								value: {
									description: 'id of the selection that the customer chose',
									type: 'string',
								},
							},
							required: ['type', 'value'],
							type: 'object',
						},
						type: 'array',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['filters', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Filters Applied',
		type: 'object',
	}
	const message = {
		event: 'Filters Applied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Filters Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes a step in a multi-step form (refers to all non check-out or reservation flow forms)
 *
 * @param {FormStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formStepCompleted(
	props: FormStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User completes a step in a multi-step form (refers to all non check-out or reservation flow forms)',
		properties: {
			context: {},
			properties: {
				properties: {
					form_id: {
						description: 'The database id of a form',
						type: 'string',
					},
					form_name: {
						description: 'The human readable name of a form',
						type: 'string',
					},
					form_type: {
						description: 'The type of form (newsletter, brochure request, DER)',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
				},
				required: [
					'form_id',
					'form_name',
					'form_type',
					'step',
					'page_name',
					'step_name',
					'step_total',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Form Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Form Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Form Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a step in a multi-step form (refers to all non check-out or reservation flow forms)
 *
 * @param {FormStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formStepViewed(
	props: FormStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User views a step in a multi-step form (refers to all non check-out or reservation flow forms)',
		properties: {
			context: {},
			properties: {
				properties: {
					form_id: {
						description: 'The database id of a form',
						type: 'string',
					},
					form_name: {
						description: 'The human readable name of a form',
						type: 'string',
					},
					form_type: {
						description: 'The type of form (newsletter, brochure request, DER)',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
				},
				required: [
					'form_type',
					'step',
					'page_name',
					'step_name',
					'step_total',
					'form_id',
					'form_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Form Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Form Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Form Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User attempts to submit a form whether the submission is successful or results in error (refers to all non check-out or reservation flow forms)
 *
 * @param {FormSubmissionAttempted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formSubmissionAttempted(
	props?: FormSubmissionAttempted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User attempts to submit a form whether the submission is successful or results in error (refers to all non check-out or reservation flow forms)',
		properties: {
			context: {},
			properties: {
				properties: {
					form_id: {
						description: 'The database id of a form',
						type: 'string',
					},
					form_name: {
						description: 'The human readable name of a form',
						type: 'string',
					},
					form_type: {
						description: 'The type of form (newsletter, brochure request, DER)',
						type: 'string',
					},
					label: {
						description: 'Descriptive label of an event',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Form Submission Attempted',
		type: 'object',
	}
	const message = {
		event: 'Form Submission Attempted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Form Submission Attempted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user successfully submits a form (refers to all non check-out or reservation flow forms)
 *
 * @param {FormSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formSubmitted(
	props: FormSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user successfully submits a form (refers to all non check-out or reservation flow forms)',
		properties: {
			context: {},
			properties: {
				properties: {
					age_group: {
						description:
							'Age group categorization as selected by a user (ex. 18 & under, 19 - 45, etc.)',
						id: '/properties/properties/properties/age_group',
						type: 'string',
					},
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					brochure_id: {
						description: 'The database id of a brochure',
						id: '/properties/properties/properties/brochure_id',
						type: 'string',
					},
					brochure_name: {
						description: 'The human readable name of a brochure',
						id: '/properties/properties/properties/brochure_name',
						type: 'string',
					},
					captcha_score: {
						description: "User's captcha score",
						id: '/properties/properties/properties/captcha_score',
						type: 'number',
					},
					form_id: {
						description: 'The database id of a form',
						id: '/properties/properties/properties/form_id',
						type: 'string',
					},
					form_name: {
						description: 'The human readable name of a form',
						id: '/properties/properties/properties/form_name',
						type: 'string',
					},
					form_type: {
						description: 'The type of form (newsletter, brochure request, DER)',
						id: '/properties/properties/properties/form_type',
						type: 'string',
					},
					label: {
						description: 'Descriptive label of an event',
						id: '/properties/properties/properties/label',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					referral_source: {
						description:
							'How a user was referred to the site as selected by a user (ex. Received a Brochure, Received an Email, etc.)',
						id: '/properties/properties/properties/referral_source',
						type: 'string',
					},
					topic_interests: {
						description: 'Topics of interest as selected by a user',
						id: '/properties/properties/properties/topic_interests',
						items: {
							description: '',
							id: '/properties/properties/properties/topic_interests/items',
							type: 'string',
						},
						type: 'array',
					},
					transaction_id: {
						description:
							'Database ID for the web transaction (e.g. form submission)',
						id: '/properties/properties/properties/transaction_id',
						type: 'integer',
					},
					travel_preference: {
						description:
							'Travel preference of the guest, e.g. travel solo, with guests, etc.',
						id: '/properties/properties/properties/travel_preference',
						type: 'string',
					},
				},
				required: ['form_name', 'form_type'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Form Submitted',
		type: 'object',
	}
	const message = {
		event: 'Form Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Form Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user views a form (analogous to an impression) (refers to all non check-out or reservation flow forms)
 *
 * @param {FormViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formViewed(
	props: FormViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user views a form (analogous to an impression) (refers to all non check-out or reservation flow forms)',
		properties: {
			context: {},
			properties: {
				properties: {
					form_id: {
						description: 'The database id of a form',
						type: 'string',
					},
					form_name: {
						description: 'The human readable name of a form',
						type: 'string',
					},
					form_type: {
						description: 'The type of form (newsletter, brochure request, DER)',
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['form_name', 'form_type', 'page_name', 'form_id'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Form Viewed',
		type: 'object',
	}
	const message = {
		event: 'Form Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Form Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any link featured in the global site navigation including header or footer
 *
 * @param {GlobalNavigationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function globalNavigationClicked(
	props: GlobalNavigationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User clicks on any link featured in the global site navigation including header or footer',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					navigation_category: {
						description:
							"Navigation category, i.e. main navigational category that user clicked through such as 'Destinations', 'Ships', etc",
						id: '/properties/properties/properties/navigation_category',
						type: 'string',
					},
					navigation_level: {
						description:
							"Whether user clicked on element in the 'header' or 'footer'",
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					text: {
						description: 'The text value of a button or rich text link',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API',
						type: 'string',
					},
				},
				required: ['url', 'text', 'navigation_level', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Global Navigation Clicked',
		type: 'object',
	}
	const message = {
		event: 'Global Navigation Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Global Navigation Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any (non-design element) image on the website
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageClicked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on any (non-design element) image on the website',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Image Clicked',
		type: 'object',
	}
	const message = {
		event: 'Image Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Image Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user views a (non-design element) image (analogous to an impression)
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A user views a (non-design element) image (analogous to an impression)',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Image Viewed',
		type: 'object',
	}
	const message = {
		event: 'Image Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Image Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User places a phone call via an Invoca number (i.e. a phone number that's dynamically replaced on the website)
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invocaCallReceived(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"User places a phone call via an Invoca number (i.e. a phone number that's dynamically replaced on the website)",
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Invoca Call Received',
		type: 'object',
	}
	const message = {
		event: 'Invoca Call Received',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Invoca Call Received',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any rich text link on the website
 *
 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkClicked(
	props: LinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on any rich text link on the website',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					link_style: {
						description: 'Style of the link, e.g. rich text, button, etc',
						enum: ['button', 'module', 'rich text'],
						id: '/properties/properties/properties/link_style',
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					text: {
						description: 'The text value of a button or rich text link',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page. ',
						type: 'string',
					},
				},
				required: ['page_name', 'text', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Link Clicked',
		type: 'object',
	}
	const message = {
		event: 'Link Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any component featured in a module
 *
 * @param {ModuleClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleClicked(
	props: ModuleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on any component featured in a module',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					component_id: {
						description: 'The database ID of the component',
						id: '/properties/properties/properties/component_id',
						type: 'string',
					},
					component_name: {
						description: 'The human readable name of the component',
						id: '/properties/properties/properties/component_name',
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						id: '/properties/properties/properties/module_type_id',
						type: ['string', 'null'],
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						id: '/properties/properties/properties/module_type_name',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					page_section: {
						description:
							"Section of the page, e.g. 'highlights', 'day by day', etc. ",
						id: '/properties/properties/properties/page_section',
						type: 'string',
					},
					position: {
						description: 'Position in the list (ex. 3)',
						id: '/properties/properties/properties/position',
						type: 'integer',
					},
					text: {
						description: 'The text value of a button or rich text link',
						id: '/properties/properties/properties/text',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API. In the case of Button Clicked or Link Clicked event this property should pass the destination URL in the event the user is being directed to another page, else pass the url of the current page',
						id: '/properties/properties/properties/url',
						type: 'string',
					},
				},
				required: ['page_name', 'module_type_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Module Clicked',
		type: 'object',
	}
	const message = {
		event: 'Module Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Module Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User closes a module (ex. modal / popups)
 *
 * @param {ModuleClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleClosed(
	props: ModuleClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User closes a module (ex. modal / popups)',
		properties: {
			context: {},
			properties: {
				properties: {
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['module_type_name', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Module Closed',
		type: 'object',
	}
	const message = {
		event: 'Module Closed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Module Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with a module, e.g. cycles through carousel, views highlights within an itinerary card, etc.
 *
 * @param {ModuleInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleInteraction(
	props: ModuleInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User interacts with a module, e.g. cycles through carousel, views highlights within an itinerary card, etc. ',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						id: '/properties/properties/properties/brand',
						type: 'string',
					},
					component_name: {
						description: 'The human readable name of the component',
						id: '/properties/properties/properties/component_name',
						type: 'string',
					},
					count_components: {
						description: 'The count of components within a given module',
						id: '/properties/properties/properties/count_components',
						type: 'integer',
					},
					interaction_type: {
						description: 'The type of module interaction',
						enum: [
							'carousel',
							'closure',
							'expansion',
							'list scroll',
							'map center',
							'map compass',
							'map exit full screen',
							'map marker',
							'map scroll',
							'map view full screen',
							'map zoom in',
							'map zoom out',
							'open',
							'share email',
							'share facebook',
							'share link',
							'share twitter',
							'tab',
							'vertical list',
							'view on map',
						],
						id: '/properties/properties/properties/interaction_type',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						id: '/properties/properties/properties/locale',
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						id: '/properties/properties/properties/module_type_id',
						type: ['string', 'null'],
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						id: '/properties/properties/properties/module_type_name',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					page_section: {
						description:
							"Section of the page, e.g. 'highlights', 'day by day', etc. ",
						id: '/properties/properties/properties/page_section',
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						id: '/properties/properties/properties/page_type_id',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						id: '/properties/properties/properties/page_type_name',
						type: 'string',
					},
					position: {
						description: 'Position in the product list (ex. 3)',
						id: '/properties/properties/properties/position',
						type: 'integer',
					},
					text: {
						description: 'The text value of a button or rich text link',
						id: '/properties/properties/properties/text',
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Module Interaction',
		type: 'object',
	}
	const message = {
		event: 'Module Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Module Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A modal or popup is presented to user after being triggered based on combination of behavioral and/or explicit interactions
 *
 * @param {ModuleOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleOpened(
	props: ModuleOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'A modal or popup is presented to user after being triggered based on combination of behavioral and/or explicit interactions',
		properties: {
			context: {},
			properties: {
				properties: {
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['module_type_name', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Module Opened',
		type: 'object',
	}
	const message = {
		event: 'Module Opened',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Module Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a module on a page (analogous to an impression)
 *
 * @param {ModuleViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function moduleViewed(
	props: ModuleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User views a module on a page (analogous to an impression)',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						type: ['string', 'null'],
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
				},
				required: ['module_type_name', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Module Viewed',
		type: 'object',
	}
	const message = {
		event: 'Module Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Module Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order, e.g. by successfully submitting a payment
 *
 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props?: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User completed the order, e.g. by successfully submitting a payment',
		properties: {
			context: {},
			properties: {
				properties: {
					booking_number: {
						description: 'ID associated with the booking',
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					cabin_quantity: {
						description: 'The number of cabins selected by the user',
						type: 'integer',
					},
					cabins: {
						description: 'Cabins available on a ship',
						items: {
							description: '',
							properties: {
								cabin_category_description: {
									description:
										'The human readable description of a cabin category',
									type: 'string',
								},
								cabin_category_id: {
									description: 'The database id of a cabin category',
									type: 'string',
								},
								passenger_adult_count: {
									description: 'The number of adult occupants of a cabin',
									type: 'integer',
								},
								passenger_child_count: {
									description: 'The number of child occupants of a cabin',
									type: 'integer',
								},
								price: {
									description: 'Per person price ($) of the cabin',
									type: 'number',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						type: 'string',
					},
					deposit_amount: {
						description:
							'Total amount of deposit required to secure reservation',
						type: 'number',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					passenger_total_count: {
						description:
							'Total number of passengers associated with the booking',
						type: 'integer',
					},
					payment_method: {
						description: 'Payment method chosen',
						type: 'string',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					revenue: {
						description: 'Total value of the order',
						type: 'number',
					},
					role: {
						description: "The user's role",
						enum: ['guest', 'travel agent'],
						type: 'string',
					},
					server_side: {
						description:
							'Whether event was triggered via a server-side source as opposed to JS',
						type: 'boolean',
					},
					ship_id: {
						description: 'The database id of a ship',
						type: 'string',
					},
					ship_name: {
						description: 'The human readable name of a ship',
						type: 'string',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Order Completed',
		type: 'object',
	}
	const message = {
		event: 'Order Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Passes page performance metrics
 *
 * @param {PagePerformanceCaptured} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pagePerformanceCaptured(
	props: PagePerformanceCaptured,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Passes page performance metrics',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					connection_type: {
						description: 'Type of connection the user has, e.g. 4g, wifi, etc ',
						type: 'string',
					},
					count_cls: {
						description: 'Count of cumulative layout shifts',
						type: 'integer',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_cls: {
						description: 'Cumulative Layout Shift (CLS) metric of the page',
						type: 'number',
					},
					page_fcp: {
						description: 'First Contentful Paint (FCP) metric of the page',
						type: 'number',
					},
					page_fid: {
						description: 'First Input Delay (FID) metric of the page',
						type: 'number',
					},
					page_lcp: {
						description: 'Largest Contentful Paint (LCP) of the page',
						type: 'number',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					page_ttfb: {
						description: 'Time to First Byte (TTFB) of the page',
						type: 'number',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
				},
				required: ['page_type_name', 'page_type_id', 'page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Page Performance Captured',
		type: 'object',
	}
	const message = {
		event: 'Page Performance Captured',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Performance Captured',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Heartbeat relative to page length, fires the user has scrolled at quarterly intervals (25%, 50%, 75% and 100%)
 *
 * @param {PageScrolled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageScrolled(
	props: PageScrolled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Heartbeat relative to page length, fires the user has scrolled at quarterly intervals (25%, 50%, 75% and 100%)',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					depth: {
						description:
							'The quarterly interval / heartbeat for scroll depth of a page or screen',
						type: 'integer',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							description: '',
							properties: {
								name: {
									description: 'Name of the product being viewed',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
				},
				required: ['page_name', 'depth'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Page Scrolled',
		type: 'object',
	}
	const message = {
		event: 'Page Scrolled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Scrolled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User loads a page on the website
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User loads a page on the website',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					brochures: {
						description: 'Brochures associated with the product or content',
						items: {
							description: '',
							properties: {
								brochure_id: {
									description: 'The database id of a brochure',
									type: 'string',
								},
								brochure_name: {
									description: 'The human readable name of a brochure',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					deep_link: {
						description:
							"Value of anchor tag that deep links user to specific content within a page, e.g. within page call for https://www.expeditions.com/destinations/belize-and-guatemala#daily-expedition-reports we would pass 'daily-expedition-reports' as the deep_link value",
						type: 'string',
					},
					is_landing: {
						description:
							"Whether page viewed is the user's landing page (i.e. where they entered the site either directly or from another domain)",
						type: 'boolean',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description: 'Human readable name for the page',
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
					path: {
						description:
							'Path portion of the URL of the page. Equivalent to canonical path which defaults to location.pathname from the DOM API',
						type: 'string',
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							description: '',
							properties: {
								brand: {
									description: 'Brand associated with the product or content',
									type: 'string',
								},
								image_url: {
									description: 'Image url of the product',
									type: 'string',
								},
								name: {
									description: 'Name of the product being viewed',
									type: 'string',
								},
								product_id: {
									description:
										'Database id of the product being viewed (analogous to an itinerary id)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					referrer: {
						description:
							'Full URL of the previous page. Equivalent to document.referrer from the DOM API',
						type: 'string',
					},
					search: {
						description:
							'Query string portion of the URL of the page. Equivalent to location.search from the DOM API',
						type: 'string',
					},
					ships: {
						description: 'Ships associated with the product or content',
						items: {
							description: '',
							properties: {
								ship_id: {
									description: 'The database id of a ship',
									type: 'string',
								},
								ship_name: {
									description: 'The human readable name of a ship',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					tags_applied: {
						description: 'Array of tag categories applied ',
						items: {
							description: '',
							enum: [
								'audience',
								'content pillar',
								'content type',
								'destination',
								'funnel',
								'geographies',
								'interest or activity',
								'itinerary',
								'promotions',
								'series or campaign',
								'ship',
								'source',
								'trip type',
							],
							type: 'string',
						},
						type: 'array',
					},
					tags_audience: {
						description: 'Array of audience tags applied to content',
						type: 'array',
					},
					tags_content_pillar: {
						description: 'Array of trip type tags applied to content ',
						type: 'array',
					},
					tags_content_type: {
						description: 'Array of content type tags applied to content ',
						type: 'array',
					},
					tags_destination: {
						description: 'Array of destination tags applied to content',
						type: 'array',
					},
					tags_funnel: {
						description: 'Array of funnel tags applied to content ',
						type: 'array',
					},
					tags_geographies: {
						description: 'Array of geography tags applied to content ',
						type: 'array',
					},
					tags_interest_activity: {
						description: 'Array of trip type tags applied to content ',
						type: 'array',
					},
					tags_itinerary: {
						description: 'Array of itinerary tags applied to content ',
						type: 'array',
					},
					tags_promotions: {
						description: 'Array of promotions tags applied to content  ',
						type: 'array',
					},
					tags_series_campaign: {
						description: 'array of series / campaign tags applied to content ',
						type: 'array',
					},
					tags_ship: {
						description: 'Array of ship tags applied to content ',
						type: 'array',
					},
					tags_source: {
						description: 'Array of source tags applied to content ',
						type: 'array',
					},
					tags_trip_type: {
						description: 'Array of trip type tags applied to content ',
						type: 'array',
					},
					team_members: {
						description: 'Team members associated with the product or page',
						items: {
							description: '',
							properties: {
								team_member_id: {
									description: 'The database id of a team member',
									type: 'string',
								},
								team_member_name: {
									description: 'The name of a team member',
									type: 'string',
								},
								team_member_role: {
									description: 'The human readable role name of a team member',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					title: {
						description:
							'Title of the page. Equivalent to document.title from the DOM API',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API',
						type: 'string',
					},
				},
				required: [
					'title',
					'is_landing',
					'path',
					'search',
					'url',
					'name',
					'referrer',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has successfully entered payment information
 *
 * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(
	props?: PaymentInfoEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User has successfully entered payment information',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					role: {
						description: "The user's role",
						enum: ['guest', 'travel agent'],
						type: 'string',
					},
					step: {
						description:
							'Number representing a step in the checkout process or a form flow',
						type: 'integer',
					},
					step_name: {
						description:
							'Human readable name / title of a step in a multi-stage checkout or form flow',
						type: 'string',
					},
					step_total: {
						description:
							'The total number of steps in a multi-step checkout or form flow',
						type: 'integer',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Payment Info Entered',
		type: 'object',
	}
	const message = {
		event: 'Payment Info Entered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Payment Info Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a phone number that is represented on the page

 *
 * @param {PhoneNumberClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneNumberClicked(
	props: PhoneNumberClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'User clicks on a phone number that is represented on the page\n',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					phone: {
						description: 'Phone number',
						type: 'string',
					},
				},
				required: ['page_name', 'phone'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Phone Number Clicked',
		type: 'object',
	}
	const message = {
		event: 'Phone Number Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Phone Number Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on link leading to a product page
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
	props: ProductClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on link leading to a product page',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: ['category'],
							type: 'object',
						},
						type: 'array',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						type: 'string',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					index: {
						description:
							'Name of the targeted Algolia index. Maps to index in the Insights event payload',
						type: 'string',
					},
					list_id: {
						description:
							'Product or content list being viewed (analogous to Destination id)',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					objectID: {
						description:
							'The objectID of the record. Maps to objectIDs in the Algolia Insights event payload',
						type: 'string',
					},
					offers: {
						description: 'Offers associated with a product',
						items: {
							description: '',
							properties: {
								offer_id: {
									description: 'The database id of an offer',
									type: 'string',
								},
								offer_name: {
									description: 'The human readable name of an offer',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
					port_of_departure: {
						description: 'Port of departure',
						type: 'string',
					},
					position: {
						description: 'Position in the product list (ex. 3)',
						type: 'integer',
					},
					price: {
						description: 'Price ($) of the product being viewed',
						type: 'number',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					queryID: {
						description:
							'Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload',
						type: 'string',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						type: 'string',
					},
				},
				required: ['page_name', 'product_id', 'brand', 'name', 'image_url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Product Clicked',
		type: 'object',
	}
	const message = {
		event: 'Product Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User applies filter to a product list / list module
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
	props: ProductListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User applies filter to a product list / list module',
		properties: {
			context: {},
			properties: {
				properties: {
					category: {
						description:
							'Product or content category being viewed (analogous to Destination name)',
						type: ['string', 'null'],
					},
					filtered_on_page_load: {
						description:
							"Passes 'true' if filter was executed automatically upon page load, else 'false' ",
						type: 'boolean',
					},
					filters: {
						description:
							'Product or content filters that the customer is using\n',
						items: {
							description: '',
							properties: {
								type: {
									description:
										'id of the filter type that the customer is using',
									type: 'string',
								},
								value: {
									description: 'id of the selection that the customer chose',
									type: 'string',
								},
							},
							required: ['type', 'value'],
							type: 'object',
						},
						type: 'array',
					},
					index: {
						description:
							'Name of the targeted Algolia index. Maps to index in the Insights event payload',
						type: 'string',
					},
					list_id: {
						description:
							'Product or content list being viewed (analogous to Destination id)',
						type: ['string', 'null'],
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							description: '',
							properties: {
								brand: {
									description: 'Brand associated with the product or content',
									type: 'string',
								},
								categories: {
									description: 'Categories associated with the product or page',
									items: {
										description: '',
										properties: {
											category: {
												description:
													'Product or content category being viewed (analogous to Destination name)',
												type: 'string',
											},
										},
										required: ['category'],
										type: 'object',
									},
									type: 'array',
								},
								image_url: {
									description: 'Image url of the product',
									type: 'string',
								},
								name: {
									description: 'Name of the product being viewed',
									type: 'string',
								},
								offers: {
									description: 'Offers associated with a product',
									items: {
										description: '',
										properties: {
											offer_id: {
												description: 'The database id of an offer',
												type: 'string',
											},
											offer_name: {
												description: 'The human readable name of an offer',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									type: 'array',
								},
								product_id: {
									description:
										'Database id of the product being viewed (analogous to an itinerary id)',
									type: 'string',
								},
							},
							required: [
								'brand',
								'categories',
								'image_url',
								'name',
								'product_id',
							],
							type: 'object',
						},
						type: 'array',
					},
					sorts: {
						description: 'Product sorting that the customer is using',
						items: {
							description: '',
							properties: {
								type: {
									description: 'id of the sort type that the customer is using',
									type: 'string',
								},
								value: {
									description:
										'id of the selection type the customer is using (ascending, descending)',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
				},
				required: ['products', 'page_name', 'nonInteraction'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Product List Filtered',
		type: 'object',
	}
	const message = {
		event: 'Product List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a product list / list module
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
	props: ProductListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User views a product list / list module',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					category: {
						description:
							'Product or content category being viewed (analogous to Destination name)',
						type: ['string', 'null'],
					},
					index: {
						description:
							'Name of the targeted Algolia index. Maps to index in the Insights event payload',
						type: 'string',
					},
					list_id: {
						description:
							'Product or content list being viewed (analogous to Destination id)',
						type: ['string', 'null'],
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
					previous_page_name: {
						description:
							'Human readable name for the previous page that the user was on',
						type: 'string',
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							description: '',
							properties: {
								brand: {
									description: 'Brand associated with the product or content',
									type: 'string',
								},
								categories: {
									description: 'Categories associated with the product or page',
									items: {
										description: '',
										properties: {
											category: {
												description:
													'Product or content category being viewed (analogous to Destination name)',
												type: 'string',
											},
										},
										required: ['category'],
										type: 'object',
									},
									type: 'array',
								},
								image_url: {
									description: 'Image url of the product',
									type: 'string',
								},
								name: {
									description: 'Name of the product being viewed',
									type: 'string',
								},
								objectID: {
									description:
										'The objectID of the record. Maps to objectIDs in the Algolia Insights event payload',
									type: 'string',
								},
								offers: {
									description: 'Offers associated with a product',
									items: {
										description: '',
										properties: {
											offer_id: {
												description: 'The database id of an offer',
												type: 'string',
											},
											offer_name: {
												description: 'The human readable name of an offer',
												type: 'string',
											},
										},
										required: [],
										type: 'object',
									},
									type: 'array',
								},
								product_id: {
									description:
										'Database id of the product being viewed (analogous to an itinerary id)',
									type: 'string',
								},
								variant: {
									description:
										'Variant of the product (analogous to a trip id)',
									type: 'string',
								},
							},
							required: [
								'brand',
								'categories',
								'image_url',
								'name',
								'product_id',
							],
							type: 'object',
						},
						type: 'array',
					},
				},
				required: ['page_name', 'products'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Product List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Product List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a product detail page
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
	props: ProductViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User views a product detail page',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					brand: {
						description: 'Brand associated with the product or content',
						type: 'string',
					},
					categories: {
						description: 'Categories associated with the product or page',
						items: {
							description: '',
							properties: {
								category: {
									description:
										'Product or content category being viewed (analogous to Destination name)',
									type: 'string',
								},
							},
							required: ['category'],
							type: 'object',
						},
						type: 'array',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: 'string',
					},
					image_url: {
						description: 'Image url of the product',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						type: 'string',
					},
					nonInteraction: {
						description:
							'Denotes whether this is a user interaction (Google Analytics), value of 1 indicates non-interaction',
						type: 'integer',
					},
					offers: {
						description: 'Offers associated with a product',
						items: {
							description: '',
							properties: {
								offer_id: {
									description: 'The database id of an offer',
									type: 'string',
								},
								offer_name: {
									description: 'The human readable name of an offer',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					price: {
						description: 'Price ($) of the product being viewed',
						type: 'number',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						type: 'string',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						type: 'integer',
					},
				},
				required: [
					'product_id',
					'page_name',
					'trip_length',
					'price',
					'image_url',
					'brand',
					'categories',
					'name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Product Viewed',
		type: 'object',
	}
	const message = {
		event: 'Product Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views search results for products or product categories
 *
 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(
	props: ProductsSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User views search results for products or product categories',
		properties: {
			context: {},
			properties: {
				properties: {
					filters: {
						description:
							'Product or content filters that the customer is using\n',
						items: {
							description: '',
							properties: {
								type: {
									description:
										'id of the filter type that the customer is using',
									type: 'string',
								},
								value: {
									description: 'id of the selection that the customer chose',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					module_type_id: {
						description: 'The database id of a module',
						type: 'string',
					},
					module_type_name: {
						description: 'The human readable type / classification of a module',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					page_type_id: {
						description:
							'The database id of a template type / classification of a page',
						type: 'string',
					},
					page_type_name: {
						description:
							'The human readable template type / classification of a page',
						type: 'string',
					},
					query: {
						description: 'Query the user searched with\n',
						type: 'string',
					},
				},
				required: [
					'page_name',
					'module_type_id',
					'module_type_name',
					'page_type_name',
					'page_type_id',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Products Searched',
		type: 'object',
	}
	const message = {
		event: 'Products Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Products Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User successfully submits a reservation request
 *
 * @param {ReservationRequestSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reservationRequestSubmitted(
	props: ReservationRequestSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User successfully submits a reservation request',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand associated with the product or content',
						id: '/properties/properties/properties/brand',
						type: 'string',
					},
					cabin_quantity: {
						description: 'The number of cabins selected by the user',
						id: '/properties/properties/properties/cabin_quantity',
						type: 'integer',
					},
					cabins: {
						description: 'Cabins available on a ship',
						id: '/properties/properties/properties/cabins',
						items: {
							description: '',
							id: '/properties/properties/properties/cabins/items',
							properties: {
								cabin_category_description: {
									description:
										'The human readable description of a cabin category',
									id:
										'/properties/properties/properties/cabins/items/properties/cabin_category_description',
									type: 'string',
								},
								cabin_category_id: {
									description: 'The database id of a cabin category',
									id:
										'/properties/properties/properties/cabins/items/properties/cabin_category_id',
									type: 'string',
								},
								passenger_adult_count: {
									description: 'The number of adult occupants of a cabin',
									id:
										'/properties/properties/properties/cabins/items/properties/passenger_adult_count',
									type: 'integer',
								},
								passenger_child_count: {
									description: 'The number of child occupants of a cabin',
									id:
										'/properties/properties/properties/cabins/items/properties/passenger_child_count',
									type: 'integer',
								},
								price: {
									description: 'Per person price ($) of the cabin',
									id:
										'/properties/properties/properties/cabins/items/properties/price',
									type: 'number',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					checkout_id: {
						description: '',
						id: '/properties/properties/properties/checkout_id',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the transaction',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					days_until_trip: {
						description:
							"Number of days until trip departure; should equal '0' if departure is same day, '1' if tomorrow, etc. ",
						id: '/properties/properties/properties/days_until_trip',
						type: 'integer',
					},
					departure_date: {
						description: 'Departing date of a trip of a particular trip',
						id: '/properties/properties/properties/departure_date',
						type: 'string',
					},
					deposit_amount: {
						description:
							'Total amount of deposit required to secure reservation',
						id: '/properties/properties/properties/deposit_amount',
						type: 'number',
					},
					image_url: {
						description: 'Image url of the product',
						id: '/properties/properties/properties/image_url',
						type: 'string',
					},
					index: {
						description:
							'Name of the targeted Algolia index. Maps to index in the Insights event payload',
						id: '/properties/properties/properties/index',
						type: 'string',
					},
					locale: {
						description: 'Locale of website, e.g. USA, Australia, or World',
						enum: ['Australia', 'USA', 'World'],
						id: '/properties/properties/properties/locale',
						type: 'string',
					},
					name: {
						description:
							'Name of the product being viewedName of the product being viewed',
						id: '/properties/properties/properties/name',
						type: 'string',
					},
					order_id: {
						description: 'Order/transaction ID',
						id: '/properties/properties/properties/order_id',
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					passenger_total_count: {
						description:
							'Total number of passengers associated with the booking',
						id: '/properties/properties/properties/passenger_total_count',
						type: 'integer',
					},
					payment_method: {
						description: 'Payment method chosen',
						id: '/properties/properties/properties/payment_method',
						type: ['string', 'null'],
					},
					port_of_departure: {
						description: 'Port of departure',
						id: '/properties/properties/properties/port_of_departure',
						type: 'string',
					},
					product_id: {
						description:
							'Database id of the product being viewed (analogous to an itinerary id)',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					products: {
						description:
							'Products displayed in the product list (in order to pass objectID to Algolia)',
						id: '/properties/properties/properties/products',
						items: {
							description: '',
							id: '/properties/properties/properties/products/items',
							properties: {
								objectID: {
									description:
										'The objectID of the record. Maps to objectIDs in the Algolia Insights event payload',
									id:
										'/properties/properties/properties/products/items/properties/objectID',
									type: 'string',
								},
							},
							required: [],
							type: 'object',
						},
						type: 'array',
					},
					queryID: {
						description:
							'Algolia queryID that can be found in the search response when using clickAnalytics. Maps to queryID in the Insights event payload',
						id: '/properties/properties/properties/queryID',
						type: 'string',
					},
					revenue: {
						description: 'Total value of the order',
						id: '/properties/properties/properties/revenue',
						type: 'number',
					},
					ship_id: {
						description: 'The database id of a ship',
						id: '/properties/properties/properties/ship_id',
						type: 'string',
					},
					ship_name: {
						description: 'The human readable name of a ship',
						id: '/properties/properties/properties/ship_name',
						type: 'string',
					},
					transaction_id: {
						description:
							'Database ID for the web transaction (e.g. form submission)',
						id: '/properties/properties/properties/transaction_id',
						type: 'integer',
					},
					trip_length: {
						description: 'The total length of the trip (in days)',
						id: '/properties/properties/properties/trip_length',
						type: 'integer',
					},
					variant: {
						description: 'Variant of the product (analogous to a trip id)',
						id: '/properties/properties/properties/variant',
						type: 'string',
					},
				},
				required: [
					'product_id',
					'brand',
					'cabin_quantity',
					'currency',
					'revenue',
					'departure_date',
					'page_name',
					'payment_method',
					'trip_length',
					'variant',
					'image_url',
					'name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Reservation Request Submitted',
		type: 'object',
	}
	const message = {
		event: 'Reservation Request Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Reservation Request Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees a Qualaroo nudge
 *
 * @param {SawNudge} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sawNudge(
	props?: SawNudge,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User sees a Qualaroo nudge',
		properties: {
			context: {},
			properties: {
				properties: {
					noninteractive: {
						description: 'Whether qualaroo event is noninteractive ',
						type: 'boolean',
					},
					nudge_id: {
						description: 'Qualaroo nudge ID',
						type: 'string',
					},
					nudge_name: {
						description: 'Qualaroo nudge name',
						type: 'string',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Saw nudge',
		type: 'object',
	}
	const message = {
		event: 'Saw nudge',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Saw nudge', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User signs into their account
 *
 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedIn(
	props?: SignedIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User signs into their account',
		properties: {
			context: {},
			properties: {
				properties: {
					affinity_charter: {
						description: 'Whether user is an affinity & charter account holder',
						type: 'boolean',
					},
					my_lex: {
						description: 'Whether a user is a myLEX account holder',
						type: 'boolean',
					},
					travel_agent: {
						description: 'Whether a user is a travel agent account holder',
						type: 'boolean',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Signed In',
		type: 'object',
	}
	const message = {
		event: 'Signed In',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User signs out of their account
 *
 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedOut(
	props?: SignedOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User signs out of their account',
		properties: {
			context: {},
			properties: {
				properties: {
					affinity_charter: {
						description: 'Whether user is an affinity & charter account holder',
						type: 'boolean',
					},
					my_lex: {
						description: 'Whether a user is a myLEX account holder',
						type: 'boolean',
					},
					travel_agent: {
						description: 'Whether a user is a travel agent account holder',
						type: 'boolean',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Signed Out',
		type: 'object',
	}
	const message = {
		event: 'Signed Out',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User signs up and creates a new account
 *
 * @param {SignedUp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedUp(
	props?: SignedUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User signs up and creates a new account',
		properties: {
			context: {},
			properties: {
				properties: {
					affinity_charter: {
						description: 'Whether user is an affinity & charter account holder',
						type: 'boolean',
					},
					my_lex: {
						description: 'Whether a user is a myLEX account holder',
						type: 'boolean',
					},
					travel_agent: {
						description: 'Whether a user is a travel agent account holder',
						type: 'boolean',
					},
				},
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Signed Up',
		type: 'object',
	}
	const message = {
		event: 'Signed Up',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed Up', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks on any link featured in a page sub-navigation
 *
 * @param {SubNavigationClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subNavigationClicked(
	props: SubNavigationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'User clicks on any link featured in a page sub-navigation',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						type: 'string',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						type: 'string',
					},
					text: {
						description: 'The text value of a button or rich text link',
						type: 'string',
					},
					url: {
						description:
							'Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API',
						type: 'string',
					},
				},
				required: ['text', 'page_name', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Sub Navigation Clicked',
		type: 'object',
	}
	const message = {
		event: 'Sub Navigation Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Sub Navigation Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Heartbeats that you can fire every 10 seconds to track how far into the content the user is currently viewing as indicated by the position
 *
 * @param {VideoContentPlaying} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoContentPlaying(
	props: VideoContentPlaying,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Heartbeats that you can fire every 10 seconds to track how far into the content the user is currently viewing as indicated by the position',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					asset_source: {
						description: 'URL source of the asset',
						id: '/properties/properties/properties/asset_source',
						type: 'string',
					},
					full_screen: {
						description:
							'True if playback is currently in full screen mode and false otherwise',
						id: '/properties/properties/properties/full_screen',
						type: 'boolean',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					position: {
						description:
							'The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...',
						id: '/properties/properties/properties/position',
						type: 'integer',
					},
					sound: {
						description:
							'The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume',
						id: '/properties/properties/properties/sound',
						type: 'integer',
					},
					title: {
						description: 'The title of the video content',
						id: '/properties/properties/properties/title',
						type: 'string',
					},
					total_length: {
						description:
							'The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null',
						id: '/properties/properties/properties/total_length',
						type: 'integer',
					},
					video_player: {
						description:
							'The name of the video player (for example youtube, vimeo)',
						id: '/properties/properties/properties/video_player',
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Video Content Playing',
		type: 'object',
	}
	const message = {
		event: 'Video Content Playing',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Video Content Playing',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When playback is complete and only when the session is finished
 *
 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackCompleted(
	props: VideoPlaybackCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'When playback is complete and only when the session is finished',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					asset_source: {
						description: 'URL source of the asset',
						id: '/properties/properties/properties/asset_source',
						type: 'string',
					},
					full_screen: {
						description:
							'True if playback is currently in full screen mode and false otherwise',
						id: '/properties/properties/properties/full_screen',
						type: 'boolean',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					position: {
						description:
							'The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...',
						id: '/properties/properties/properties/position',
						type: 'integer',
					},
					sound: {
						description:
							'The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume',
						id: '/properties/properties/properties/sound',
						type: 'integer',
					},
					title: {
						description: 'The title of the video content',
						id: '/properties/properties/properties/title',
						type: 'string',
					},
					total_length: {
						description:
							'The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null',
						id: '/properties/properties/properties/total_length',
						type: 'integer',
					},
					video_player: {
						description:
							'The name of the video player (for example youtube, vimeo)',
						id: '/properties/properties/properties/video_player',
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Video Playback Completed',
		type: 'object',
	}
	const message = {
		event: 'Video Playback Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user presses Play
 *
 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackStarted(
	props: VideoPlaybackStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'When a user presses Play',
		properties: {
			context: {},
			properties: {
				properties: {
					app_framework: {
						description: 'Framework of the application',
						enum: ['vercel'],
						id: '/properties/properties/properties/app_framework',
						type: 'string',
					},
					asset_source: {
						description: 'URL source of the asset',
						id: '/properties/properties/properties/asset_source',
						type: 'string',
					},
					full_screen: {
						description:
							'True if playback is currently in full screen mode and false otherwise',
						id: '/properties/properties/properties/full_screen',
						type: 'boolean',
					},
					page_name: {
						description:
							"Human readable name for the page (this 'page_name' property is intended specifically for track events where 'name' is intended for page calls) ",
						id: '/properties/properties/properties/page_name',
						type: 'string',
					},
					position: {
						description:
							'The current index position in seconds of the playhead into the content/asset. This must exclude the duration of any ads ...',
						id: '/properties/properties/properties/position',
						type: 'integer',
					},
					sound: {
						description:
							'The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume',
						id: '/properties/properties/properties/sound',
						type: 'integer',
					},
					title: {
						description: 'The title of the video content',
						id: '/properties/properties/properties/title',
						type: 'string',
					},
					total_length: {
						description:
							'The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback, send null',
						id: '/properties/properties/properties/total_length',
						type: 'integer',
					},
					video_player: {
						description:
							'The name of the video player (for example youtube, vimeo)',
						id: '/properties/properties/properties/video_player',
						type: 'string',
					},
				},
				required: ['page_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Video Playback Started',
		type: 'object',
	}
	const message = {
		event: 'Video Playback Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User answers a Qualaroo nudge
	 *
	 * @param {AnsweredNudge} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	answeredNudge,
	/**
	 * User clicks on any button on the website
	 *
	 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	buttonClicked,
	/**
	 * Fires when a user opens the chat module
	 *
	 * @param {ChatOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chatOpened,
	/**
	 * Fires when a user requests a chat with an agent
	 *
	 * @param {ChatRequested} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chatRequested,
	/**
	 * User exits the checkout flow, e.g. by clicking Leave Booking, or opening some other page on the site that removes them from the flow
	 *
	 * @param {CheckoutExited} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutExited,
	/**
	 * User first enters the reservation request / booking flow
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a step in the reservation request / booking flow
	 *
	 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User viewed a step in the reservation request / booking flow
	 *
	 * @param {CheckoutStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepViewed,
	/**
	 * A user clears selection from dropdown
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dropdownItemRemoved,
	/**
	 * A user selects from a dropdown list
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dropdownItemSelected,
	/**
	 * A user toggles a dropdown list
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dropdownMenuOpened,
	/**
	 * Fires when user encounters an error
	 *
	 * @param {ErrorReceived} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorReceived,
	/**
	 * User views an A/B test (passes automatically when an Optimizely experiment is detected)
	 *
	 * @param {ExperimentViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	experimentViewed,
	/**
	 * A user applies all selected filterable attributes
	 *
	 * @param {FiltersApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filtersApplied,
	/**
	 * User completes a step in a multi-step form (refers to all non check-out or reservation flow forms)
	 *
	 * @param {FormStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formStepCompleted,
	/**
	 * User views a step in a multi-step form (refers to all non check-out or reservation flow forms)
	 *
	 * @param {FormStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formStepViewed,
	/**
	 * User attempts to submit a form whether the submission is successful or results in error (refers to all non check-out or reservation flow forms)
	 *
	 * @param {FormSubmissionAttempted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formSubmissionAttempted,
	/**
	 * A user successfully submits a form (refers to all non check-out or reservation flow forms)
	 *
	 * @param {FormSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formSubmitted,
	/**
	 * A user views a form (analogous to an impression) (refers to all non check-out or reservation flow forms)
	 *
	 * @param {FormViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formViewed,
	/**
	 * User clicks on any link featured in the global site navigation including header or footer
	 *
	 * @param {GlobalNavigationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	globalNavigationClicked,
	/**
	 * User clicks on any (non-design element) image on the website
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageClicked,
	/**
	 * A user views a (non-design element) image (analogous to an impression)
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageViewed,
	/**
	 * User places a phone call via an Invoca number (i.e. a phone number that's dynamically replaced on the website)
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invocaCallReceived,
	/**
	 * User clicks on any rich text link on the website
	 *
	 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	linkClicked,
	/**
	 * User clicks on any component featured in a module
	 *
	 * @param {ModuleClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	moduleClicked,
	/**
	 * User closes a module (ex. modal / popups)
	 *
	 * @param {ModuleClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	moduleClosed,
	/**
	 * User interacts with a module, e.g. cycles through carousel, views highlights within an itinerary card, etc.
	 *
	 * @param {ModuleInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	moduleInteraction,
	/**
	 * A modal or popup is presented to user after being triggered based on combination of behavioral and/or explicit interactions
	 *
	 * @param {ModuleOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	moduleOpened,
	/**
	 * User views a module on a page (analogous to an impression)
	 *
	 * @param {ModuleViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	moduleViewed,
	/**
	 * User completed the order, e.g. by successfully submitting a payment
	 *
	 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * Passes page performance metrics
	 *
	 * @param {PagePerformanceCaptured} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pagePerformanceCaptured,
	/**
	 * Heartbeat relative to page length, fires the user has scrolled at quarterly intervals (25%, 50%, 75% and 100%)
	 *
	 * @param {PageScrolled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageScrolled,
	/**
	 * User loads a page on the website
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User has successfully entered payment information
	 *
	 * @param {PaymentInfoEntered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentInfoEntered,
	/**
	 * User clicks on a phone number that is represented on the page
	
	 *
	 * @param {PhoneNumberClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	phoneNumberClicked,
	/**
	 * User clicks on link leading to a product page
	 *
	 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User applies filter to a product list / list module
	 *
	 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * User views a product list / list module
	 *
	 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * User views a product detail page
	 *
	 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User views search results for products or product categories
	 *
	 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsSearched,
	/**
	 * User successfully submits a reservation request
	 *
	 * @param {ReservationRequestSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reservationRequestSubmitted,
	/**
	 * User sees a Qualaroo nudge
	 *
	 * @param {SawNudge} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sawNudge,
	/**
	 * User signs into their account
	 *
	 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedIn,
	/**
	 * User signs out of their account
	 *
	 * @param {SignedOut} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedOut,
	/**
	 * User signs up and creates a new account
	 *
	 * @param {SignedUp} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedUp,
	/**
	 * User clicks on any link featured in a page sub-navigation
	 *
	 * @param {SubNavigationClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subNavigationClicked,
	/**
	 * Heartbeats that you can fire every 10 seconds to track how far into the content the user is currently viewing as indicated by the position
	 *
	 * @param {VideoContentPlaying} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoContentPlaying,
	/**
	 * When playback is complete and only when the session is finished
	 *
	 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackCompleted,
	/**
	 * When a user presses Play
	 *
	 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackStarted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/lindblad/protocols/tracking-plans/rs_1vuEDKUXmMSoL2B16qPKl5nFBYD`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
