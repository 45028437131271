import LinkItem from '@/common/interfaces/LinkItem';
import SelectItem from '@/common/interfaces/SelectItem';
import { isSSR } from './commonUtils';

export const toggleClass = (element: HTMLElement | undefined, className: string) => {
  if (element) element.classList.toggle(className);
};

export const removeClass = (element: HTMLElement | undefined, className: string) => {
  if (element) element.classList.remove(className);
};

export const addClass = (element: HTMLElement | undefined, className: string) => {
  if (element) element.classList.add(className);
};

export const hideElement = (element: HTMLElement | undefined) => {
  if (element) element.style.display = 'none';
};

export const showElement = (element: HTMLElement | undefined) => {
  if (element) element.style.display = '';
};

export const setShow = (element: HTMLElement | undefined, isShow: boolean) => {
  if (isShow) {
    showElement(element);
  } else {
    hideElement(element);
  }
};

export const distanceFromCenterOfScreen = (element: HTMLElement | null) => {
  if (element) {
    const windowCenter = window.pageYOffset + window.innerHeight / 2;
    const elementCenter = element.offsetTop + element.clientHeight / 2;

    return Math.abs(windowCenter - elementCenter);
  }
  return 1000000;
};

export function toArray<T>(nodes: ArrayLike<T>) {
  return Array.from(nodes);
}

export const qsa = (selector: string, parent?: HTMLElement): HTMLElement[] => {
  return toArray((parent || document).querySelectorAll(selector));
};

export const matchMediaWindow = (media: string) => {
  if (isSSR()) return false;

  return window.matchMedia(media).matches;
};

export function pageOffset(el: Element) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function modulePageOffset(uuid?: string) {
  const div = document.querySelector(`[uuid="${uuid}"]`);
  if (div) {
    return pageOffset(div);
  }
  return null;
}

export function fixDropdownAudioEye(listContainer, list: any[], isClearable: boolean = false) {
  if (!list.length) {
    list = [{ Text: 'Item 1', Value: 'Item 1'},{ Text: 'Item 2', Value: 'Item 2'}]
  } else if (list.length === 1) {
    list.push({ Text: 'Item 2', Value: 'Item 2'})
  }
  listContainer.removeAttribute('role')
  const listBox = listContainer.querySelector('.vs__dropdown-toggle')
  listBox.removeAttribute('role')
  listBox.removeAttribute('aria-owns')
  listContainer.querySelector('.vs__search')?.removeAttribute('role')
  listContainer.querySelector('.vs__open-indicator')?.remove()
  if (!isClearable) {
    listContainer.querySelector('.vs__clear')?.remove()
  } else {
    const clearBtn = listContainer.querySelector('.vs__clear')
    if (clearBtn) {
      clearBtn.removeAttribute('title')
      clearBtn.querySelector('svg').setAttribute('aria-label', clearBtn.getAttribute('aria-label'))
      clearBtn.removeAttribute('aria-label')
    }
  }
  const listEl = listContainer.querySelector('ul')
  listEl.removeAttribute('role')
  list.forEach((c, i) => {
    listEl.innerHTML += `
      <li id="vs${listEl.id.charAt(2)}__option-${i}" class="vs__dropdown-option">${c.Title || c.Text || 'Item'}</li>
    `
  })
}
